import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  Box,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useTheme,
  Link,
} from "@mui/material";
import { t } from "i18next";
import { useHistory } from "react-router-dom";

import { CommonTextField, Page } from "@APP/components";
import WcfFooter from "@APP/components/layout/WCFLayout";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getUserOrganisation,
  getWorkingCapitalFinanceDetails,
  setActiveStep,
  setCompanyProfileFormData,
  setCustomer,
  setNextPath,
  setNextStepActive,
} from "@APP/redux";
import { API } from "@APP/services";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";
import { countries, industries } from "@APP/views/wcf/utils/dropdown";
import { ERPOrgType, WcfCustomer } from "@APP/types";
import { WCF_CUSTOMER_ERROR_CODE } from "@APP/services/api";
import { useAlert } from "@APP/hooks";
import { formatErrorMessage } from "@APP/utils";
import { DatePicker } from "@mui/x-date-pickers";

import { amendValues, dateFormatter } from "../utils/utils";
import CONFIG from "@APP/config";

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type propsState = {
  location: {
    state: {
      customer: WcfCustomer;
    };
  };
};

function CompanyProfileForm(props: propsState) {
  const propsData = props.location.state;
  const custPropData = propsData?.customer || null;

  const theme = useTheme();
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    wcfFormData: { companyProfileForm },
    customer,
  } = useSelector(getWorkingCapitalFinanceDetails);
  const org = useSelector(getUserOrganisation);

  const [wcfCustomer, setWcfCustomer] = useState<WcfCustomer | null>(null);
  const [websiteChanged, setWebsiteChanged] = useState(false);
  // will be used for decoupling logic from WcfFooter
  // const [custNotFound, setCustNotFound] = useState(false);

  const isOtherOrg =
    org?.orgType === ERPOrgType.OTHER_ORG ||
    org?.orgType === ERPOrgType.LIMITED_COMPANY ||
    org?.orgType === ERPOrgType.LIMITED_LIABILITY_PARTNERSHIP;

  const validate = (values: {
    industry: string;
    website: string;
    tradingName: string;
    tradingAddress: string;
    city: string;
    postcode: string;
    country: string;
    privacyPolicy: boolean;
    creditCheck: boolean;
    startTrade: string;
    vatNumber: string | null;
    registeredCompanyName?: string | null;
    companyRegistrationNumber?: string | null;
  }) => {
    let errors = {};
    if (!values.industry) {
      errors = {
        ...errors,
        industry: "Business industry is a required field.",
      };
    }
    const websiteRegex = /^https?:\/\/(?:www\.)?[^\s$.?#]+\.[^\s$.?#]+[^\s]*$/;
    if (values.website && !websiteRegex.test(values.website)) {
      errors = {
        ...errors,
        website:
          'Invalid URL format. URL must start with "https://" or "http://" and be an absolute URI.',
      };
    }
    if (!values.tradingName) {
      errors = {
        ...errors,
        tradingName: "Trading name is a required field.",
      };
    }
    if (!values.tradingAddress) {
      errors = {
        ...errors,
        tradingAddress: "Trading address is a required field.",
      };
    }
    if (!values.city) {
      errors = {
        ...errors,
        city: "Trading city is a required field.",
      };
    }
    const postcodeRegex =
      /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Za-z][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Za-z][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;
    if (!values.postcode) {
      errors = {
        ...errors,
        postcode: "Postcode is required.",
      };
    } else if (!postcodeRegex.test(values.postcode)) {
      errors = {
        ...errors,
        postcode: "Please enter correct postcode",
      };
    }
    if (!values.privacyPolicy) {
      errors = {
        ...errors,
        privacyPolicy: "Please accept the privacy policy.",
      };
    }
    if (!values.creditCheck) {
      errors = {
        ...errors,
        creditCheck: "Please give consent to credit check.",
      };
    }
    if (!values.startTrade || values.startTrade.toString() === "Invalid Date") {
      errors = {
        ...errors,
        startTrade: "Trading date is required.",
      };
    } else if (new Date(values.startTrade) > new Date()) {
      errors = {
        ...errors,
        startTrade: "Please enter a valid date",
      };
    }
    if (!values.country) {
      errors = {
        ...errors,
        city: "Country is a required field.",
      };
    }
    const vatNumRegex = /^(?:GB)?\d{9}$|^(?:GB)?\d{12}$/;
    if (!vatNumRegex.test(values.vatNumber as string)) {
      errors = {
        ...errors,
        vatNumber: "Field must be either 9 or 12 characters long",
      };
    }
    if (isOtherOrg && !values.registeredCompanyName) {
      errors = {
        ...errors,
        registeredCompanyName: "Please enter registered Company name",
      };
    }
    const companyRegNumRegex = /^([A-Z]{2}|[0-9]{2}|(R)([0-9]{1}))([0-9]{6})$/;
    if (isOtherOrg && !values.companyRegistrationNumber) {
      errors = {
        ...errors,
        companyRegistrationNumber: "Please enter Company registration number",
      };
    }
    if (
      values.companyRegistrationNumber &&
      !companyRegNumRegex.test(values.companyRegistrationNumber) &&
      isOtherOrg
    ) {
      errors = {
        ...errors,
        companyRegistrationNumber: "Please enter a valid Company registration number",
      };
    }
    return errors;
  };

  const handleAddHttp = () => {
    if (!org?.website) {
      return setFieldValue("website", "https://");
    }
    if ((org?.website && org?.website?.includes("https://")) || org?.website?.includes("http://")) {
      return;
    }
    if (org?.website && !org?.website?.includes("https://") && !websiteChanged) {
      return setFieldValue("website", `https://${org.website}`);
    }
  };

  useEffect(() => {
    (async () => {
      dispatch(setActiveStep(0));
      dispatch(setNextPath(SCREEN_PATHS.WORKING_CAPITAL_FINANCE));
      await fetchWcfCustomer();
      await setDefaultFormState();
      dispatch(setNextStepActive(true));
    })();
  }, []);

  const setDefaultFormState = async () => {
    setFieldValue(
      "startTrade",
      new Date(
        (custPropData?.tradingFromDate as string) || (wcfCustomer?.tradingFromDate as string),
      ),
    );
    setFieldValue(
      "tradingName",
      custPropData?.tradingName ||
        wcfCustomer?.tradingName ||
        org?.companyInfo.name ||
        companyProfileForm.tradingName,
    );
    const address = isOtherOrg
      ? custPropData?.registeredAddress?.street || wcfCustomer?.registeredAddress?.street
      : custPropData?.tradingAddress?.street || wcfCustomer?.tradingAddress?.street;
    const city = isOtherOrg
      ? custPropData?.registeredAddress?.cityOrTown || wcfCustomer?.registeredAddress?.cityOrTown
      : custPropData?.tradingAddress?.cityOrTown || wcfCustomer?.tradingAddress?.cityOrTown;
    const postcode = isOtherOrg
      ? custPropData?.registeredAddress?.postcode || wcfCustomer?.registeredAddress?.postcode
      : custPropData?.tradingAddress?.postcode || wcfCustomer?.tradingAddress?.postcode;

    setFieldValue(
      "tradingAddress",
      address || org?.companyInfo?.address?.lines.join(", ") || companyProfileForm.tradingAddress,
    );
    setFieldValue("city", city || org?.companyInfo?.address?.city || companyProfileForm.city);
    setFieldValue(
      "postcode",
      postcode || org?.companyInfo?.address?.postcode || companyProfileForm.postcode,
    );
    setFieldValue(
      "country",
      custPropData?.registeredAddress?.country ||
        wcfCustomer?.registeredAddress?.country ||
        wcfCustomer?.tradingAddress?.country ||
        companyProfileForm.country,
    );
    setFieldValue(
      "vatNumber",
      custPropData?.vatInformation?.vatNumber ||
        wcfCustomer?.vatInformation?.vatNumber ||
        org?.taxInfo.vatRegistrationNumber ||
        null,
    );
    setFieldValue(
      "companyRegistrationNumber",
      custPropData?.companyRegistrationNumber ||
        wcfCustomer?.companyRegistrationNumber ||
        org?.taxInfo?.utrNumber,
    );
    setFieldValue(
      "registeredCompanyName",
      custPropData?.registeredCompanyName ||
        wcfCustomer?.registeredCompanyName ||
        org?.companyInfo?.name ||
        org?.name ||
        null,
    );
    setFieldValue(
      "website",
      custPropData?.website || wcfCustomer?.website || org?.website || companyProfileForm.website,
    );
    setFieldValue(
      "industry",
      custPropData?.industry || wcfCustomer?.industry || companyProfileForm.industry,
    );
  };

  useEffect(() => {
    dispatch(
      setCustomer({
        ...customer,
        tradingFromDate: new Date(wcfCustomer?.tradingFromDate as string).toString(),
      }),
    );
  }, [wcfCustomer?.tradingFromDate]);

  const { errors, touched, handleBlur, values, setFieldTouched, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: {
        registeredCompanyName: org?.companyInfo?.name || org?.name || null,
        industry: wcfCustomer?.industry || companyProfileForm.industry,
        website:
          customer.website ||
          companyProfileForm?.website ||
          wcfCustomer?.website ||
          (org?.website as string),
        startTrade:
          dateFormatter(wcfCustomer?.tradingFromDate as string) ||
          dateFormatter(companyProfileForm.startTrade),
        tradingName:
          wcfCustomer?.tradingName || org?.companyInfo.name || companyProfileForm.tradingName,
        tradingAddress:
          wcfCustomer?.registeredAddress?.street ||
          wcfCustomer?.tradingAddress?.street ||
          org?.companyInfo?.address?.lines.join(", ") ||
          companyProfileForm.tradingAddress,
        companyRegistrationNumber:
          (wcfCustomer?.companyRegistrationNumber as string) || (org?.taxInfo?.utrNumber as string),
        city:
          wcfCustomer?.registeredAddress?.cityOrTown ||
          wcfCustomer?.tradingAddress?.cityOrTown ||
          org?.companyInfo?.address?.city ||
          companyProfileForm.city,
        postcode:
          wcfCustomer?.tradingAddress?.postcode ||
          org?.companyInfo?.address?.postcode ||
          companyProfileForm.postcode,
        country:
          wcfCustomer?.registeredAddress?.country ||
          wcfCustomer?.tradingAddress?.country ||
          companyProfileForm.country,
        privacyPolicy: wcfCustomer?.hasConsentedToPrivacyPolicy || companyProfileForm.privacyPolicy,
        creditCheck: wcfCustomer?.hasConsentedToCreditCheck || companyProfileForm.creditCheck,
        vatNumber:
          custPropData?.vatInformation?.vatNumber ||
          wcfCustomer?.vatInformation?.vatNumber ||
          org?.taxInfo.vatRegistrationNumber ||
          null,
      },
      validate,
      onSubmit: () => {
        const companyProfileValues = { ...values, startTrade: dateFormatter(values.startTrade) };
        // will be used for decoupling logic from WcfFooter
        // submitCustomerForm();
        dispatch(setCompanyProfileFormData(companyProfileValues));
      },
    });

  useEffect(() => {
    dispatch(setNextStepActive(true));
    dispatch(setNextPath(SCREEN_PATHS.WORKING_CAPITAL_FINANCE));
    fetchWcfCustomer();
  }, []);

  useEffect(() => {
    setFieldValue("startTrade", new Date(wcfCustomer?.tradingFromDate as string));
    dispatch(
      setCustomer({
        ...customer,
        tradingFromDate: new Date(wcfCustomer?.tradingFromDate as string).toString(),
      }),
    );
  }, [wcfCustomer?.tradingFromDate]);

  useEffect(() => {
    dispatch(
      setCustomer({
        companyType: org?.orgType,
        registeredCompanyName:
          org?.orgType === "SoleTrader" ? null : org?.companyInfo?.name || org?.name || null,
        registeredAddress: {
          street: org?.companyInfo?.address?.lines[0],
          postcode: org?.companyInfo?.address?.postcode,
          cityOrTown: org?.companyInfo?.address?.city,
          countyOrState: null,
          country: values.country,
        },
        companyRegistrationNumber: values.companyRegistrationNumber ?? org?.taxInfo.utrNumber,
        tradingName: values.tradingName ?? wcfCustomer?.tradingName ?? null,
        tradingAddress: {
          street: values.tradingAddress ?? wcfCustomer?.tradingAddress ?? null,
          postcode: values.postcode ?? wcfCustomer?.tradingAddress?.postcode ?? null,
          cityOrTown: values.city ?? wcfCustomer?.tradingAddress?.cityOrTown ?? null,
          countyOrState: null,
          country: values.country,
        },
        tradingFromDate: amendValues(
          dateFormatter(wcfCustomer?.tradingFromDate as string),
          "NaN-NaN-NaN",
        ),
        industry: values.industry,
        sicCode: org?.SIC[0],
        website: values.website ?? org?.website ?? null,
        vatInformation: {
          vatNumber: org?.taxInfo.vatRegistrationNumber,
          isRegisteredForVat: !!org?.taxInfo.vatRegistrationNumber,
        },
        hasConsentedToCreditCheck: values.creditCheck,
        hasConsentedToPrivacyPolicy: values.privacyPolicy,
      }),
    );
  }, [wcfCustomer, org, companyProfileForm]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === " " && (event.target as HTMLInputElement).value.trim() === "") {
      event.preventDefault();
    }
  };

  const fetchWcfCustomer = async () => {
    try {
      const customer = await API.getWcfCustomer();
      setWcfCustomer(customer);
      if (customer && customer.status === "Submitted") {
        history.push(SCREEN_PATHS.WCF_FINANCE_LISTING);
      }
    } catch (error) {
      const errorCode = error.response?.data?.errorCode;
      // If Customer is not created, don't show any error codes.
      // will be used for decoupling logic from WcfFooter

      // setCustNotFound(errorCode === 24110);
      if (errorCode === 5004) return;
      if (!WCF_CUSTOMER_ERROR_CODE.includes(errorCode)) {
        alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
      }
    }
  };

  // will be used for decoupling logic from WcfFooter

  // const submitCustomerForm = async () => {
  //   const isSoleTrader = org?.orgType === ERPOrgType.SOLE_TRADER;
  //   const customerPayload: WcfCustomer = {
  //     companyType: org?.orgType,
  //     registeredCompanyName:
  //       org?.orgType === "SoleTrader" ? null : org?.companyInfo?.name || org?.name || null,
  //     registeredAddress: {
  //       street: org?.companyInfo?.address?.lines[0],
  //       postcode: org?.companyInfo?.address?.postcode,
  //       cityOrTown: org?.companyInfo?.address?.city,
  //       countyOrState: null,
  //       country: values.country,
  //     },
  //     companyRegistrationNumber: values.companyRegistrationNumber || org?.taxInfo.utrNumber,
  //     tradingName: values.tradingName,
  //     tradingAddress: {
  //       street: values.tradingAddress,
  //       postcode: values.postcode,
  //       cityOrTown: values.city,
  //       countyOrState: null,
  //       country: values.country,
  //     },
  //     tradingFromDate: amendValues(dateFormatter(values.startTrade), "NaN-NaN-NaN"),
  //     industry: values.industry,
  //     sicCode: org?.SIC[0],
  //     website: org?.website || values.website,
  //     vatInformation: {
  //       vatNumber: org?.taxInfo.vatRegistrationNumber,
  //       isRegisteredForVat: !!org?.taxInfo.vatRegistrationNumber,
  //     },
  //     hasConsentedToCreditCheck: values.creditCheck,
  //     hasConsentedToPrivacyPolicy: values.privacyPolicy,
  //   };

  //   try {
  //     dispatch(showLoader());
  //     await API.createWcfCustomer(customerPayload);
  //     if (wcfCustomer?.status === "Draft" || custNotFound) {
  //       await API.submitWcfCustomer();
  //       alert.open(
  //         "Success",
  //         "Your company profile has been submitted! You can now proceed to the next step.",
  //       );
  //     } else {
  //       alert.open(
  //         "Success",
  //         "Your company profile has been updated! You can now proceed to the next step.",
  //       );
  //     }
  //   } catch (err) {
  //     const errorCode = err.response?.data?.errorCode;
  //     if (WCF_API_ERROR_CODE.includes(errorCode)) {
  //       // To show exact field name on failure
  //       const errorMessage = err.response?.data?.errorMessage;
  //       const response = JSON.parse(errorMessage.split("response: ")[1]);
  //       const errorDetail = response?.error?.details?.find(
  //         (detail: { code?: string }) => detail?.code === "property_invalid_empty",
  //       );
  //       if (errorDetail) {
  //         const fieldNameMatch = errorDetail.message.match(/'([^']+)'/);
  //         if (fieldNameMatch && fieldNameMatch.length > 1) {
  //           const fieldName = fieldNameMatch[1]
  //             .replace(/_/g, " ")
  //             .replace(/\b\w/g, (c: string) => c.toUpperCase());
  //           alert.open(
  //             "Error",
  //             `We were unable to submit your application, please update "${fieldName}" and resubmit your application.`,
  //           );
  //         }
  //       } else {
  //         alert.open("Error", "We were unable to submit your application, please try again later.");
  //       }
  //     } else {
  //       alert.open("Error", "We were unable to submit your application, please try again later ");
  //     }
  //   } finally {
  //     dispatch(hideLoader());
  //   }
  // };

  return (
    <Page display="flex" flexDirection="column" p={0}>
      <form style={{ width: "100%" }}>
        <Box p={3}>
          <Card
            elevation={1}
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              padding: "32px",
              alignItems: "center",
              borderRadius: "5px",
            }}>
            <WcfStepper style={{ width: "100%" }} />
            <Grid container mt={1}>
              <Grid item>
                <Typography
                  variant="h4"
                  component="p"
                  fontWeight={600}
                  color="primary"
                  paddingBottom={2}
                  id="companyProfileFormTitle">
                  Complete company profile
                </Typography>
                <Grid container spacing={1} pb={2}>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                      We’re almost there! We were able to create your company profile and just need
                      a few additional details.
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" pb={1}>
                      What industry do you work in? *
                    </Typography>
                    <TextField
                      id="wcfCompanyFormIndustryInput"
                      select
                      name="industry"
                      label="Industry:"
                      error={Boolean(touched.industry && errors.industry)}
                      value={values.industry}
                      helperText={touched.industry && errors.industry}
                      onChange={(e) => {
                        setFieldValue("industry", e.target.value);
                        dispatch(
                          setCustomer({
                            ...customer,
                            industry: e.target.value,
                          }),
                        );
                      }}
                      onBlur={handleBlur}
                      sx={{ width: "100%" }}>
                      {industries.map((value) => (
                        <MenuItem
                          key={value.value}
                          value={value.value}
                          id={"wcfCompanyFormIndustrySelect" + value.value}>
                          {value.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" pb={1}>
                      What is your company website?
                    </Typography>
                    <CommonTextField
                      id="wcfCompanyFormWebsiteInput"
                      label="Website:"
                      name="website"
                      error={Boolean(touched.website && errors.website)}
                      helperText={touched?.website && errors?.website}
                      value={values.website}
                      onClick={handleAddHttp}
                      onFocus={handleAddHttp}
                      onChange={(e) => {
                        setWebsiteChanged(true);
                        setFieldValue("website", e.target.value);
                        dispatch(
                          setCustomer({
                            ...customer,
                            website: amendValues(e.target.value),
                          }),
                        );
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyPress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                {isOtherOrg && (
                  <Grid container spacing={1} mt={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6" my={1}>
                        What is your registered company name? *
                      </Typography>
                    </Grid>
                    <Grid container spacing={1} mt={1}>
                      <Grid item xs={12}>
                        <CommonTextField
                          id="wcfCompanyFormRegisterCompanyNameInput"
                          label="Registered Company Name:*"
                          name="registeredCompanyName"
                          value={values.registeredCompanyName}
                          inputProps={{ maxLength: 100, id: "registeredCompanyName" }}
                          error={Boolean(
                            touched.registeredCompanyName && errors.registeredCompanyName,
                          )}
                          helperText={touched.registeredCompanyName && errors.registeredCompanyName}
                          onChange={(e) => {
                            setFieldValue("registeredCompanyName", e.target.value);
                            dispatch(
                              setCustomer({
                                ...customer,
                                registeredCompanyName: e.target.value,
                              }),
                            );
                          }}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyPress}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          sx={{ width: "100%" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {isOtherOrg && (
                  <Grid container spacing={1} mt={1}>
                    <Grid item xs={12}>
                      <Typography variant="h6" pb={1}>
                        What is your company registration number? *
                      </Typography>
                      <CommonTextField
                        id="wcfCompanyFormRegisterCompanyNumberInput"
                        label="Company Registration Number:*"
                        name="companyRegistrationNumber"
                        error={Boolean(
                          touched.companyRegistrationNumber && errors.companyRegistrationNumber,
                        )}
                        helperText={
                          touched?.companyRegistrationNumber && errors?.companyRegistrationNumber
                        }
                        value={values.companyRegistrationNumber}
                        onChange={(e) => {
                          setFieldValue("companyRegistrationNumber", e.target.value);
                          dispatch(
                            setCustomer({
                              ...customer,
                              companyRegistrationNumber:
                                e.target.value === "" ? null : e.target.value,
                            }),
                          );
                        }}
                        onBlur={handleBlur}
                        onKeyDown={handleKeyPress}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: "100%" }}
                      />
                    </Grid>
                  </Grid>
                )}
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12}>
                    <Typography variant="h6" pb={1}>
                      When did you start trading? *
                    </Typography>
                    <DatePicker
                      sx={{ width: "100%" }}
                      slotProps={{
                        textField: {
                          error: Boolean(touched.startTrade && errors.startTrade),
                          helperText:
                            touched.startTrade && (errors.startTrade as string | undefined),
                          name: "startTrade",
                          id: "wcfCompanyFormTradingDateInput",
                          onClick: () =>
                            !touched.startTrade && setFieldTouched("startTrade", true, false),
                        },
                      }}
                      onChange={(value) => {
                        !touched.startTrade && setFieldTouched("startTrade", true, false);
                        setFieldValue("startTrade", isNaN(Number(value)) ? null : value, true);
                        dispatch(
                          setCustomer({
                            ...customer,
                            tradingFromDate: value as string,
                          }),
                        );
                      }}
                      value={values.startTrade}
                      label="Trading Date: *"
                      format="dd-MM-yyyy"
                      aria-label="company date picker"
                      disableFuture
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" my={1}>
                    VAT number
                  </Typography>
                </Grid>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12}>
                    <CommonTextField
                      label="VAT number:"
                      name="vatNumber"
                      id="wcfCompanyFormVatNumberInput"
                      value={values.vatNumber}
                      error={Boolean(touched.vatNumber && errors.vatNumber)}
                      helperText={touched.vatNumber && errors.vatNumber}
                      onChange={(e) => {
                        setFieldValue("vatNumber", e.target.value);
                        dispatch(
                          setCustomer({
                            ...customer,
                            vatInformation: {
                              ...customer.vatInformation,
                              vatNumber: e.target.value,
                            },
                          }),
                        );
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyPress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" my={1}>
                    Please provide your{" "}
                    {isOtherOrg
                      ? "registered name and registered address"
                      : "trading name and trading address"}{" "}
                    *
                  </Typography>
                </Grid>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12}>
                    <CommonTextField
                      label={isOtherOrg ? `Registered Name: *` : `Trading Name: *`}
                      name="tradingName"
                      id="wcfCompanyFormCommonRegOrTradeNameInput"
                      inputProps={{ maxLength: 100 }}
                      value={values.tradingName}
                      error={Boolean(touched.tradingName && errors.tradingName)}
                      helperText={touched.tradingName && errors.tradingName}
                      onChange={(e) => {
                        setFieldValue("tradingName", e.target.value);
                        dispatch(
                          setCustomer({
                            ...customer,
                            tradingName: e.target.value === "" ? null : e.target.value,
                          }),
                        );
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyPress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1} py={2}>
                  <Grid item xs={12} pb={1}>
                    <CommonTextField
                      label={isOtherOrg ? `Registered Address: *` : `Trading Address: *`}
                      name="tradingAddress"
                      id="wcfCompanyFormCommonRegOrTradeAddressInput"
                      value={values.tradingAddress}
                      error={Boolean(touched.tradingAddress && errors.tradingAddress)}
                      helperText={touched.tradingAddress && errors.tradingAddress}
                      inputProps={{ maxLength: 100 }}
                      onChange={(e) => {
                        setFieldValue("tradingAddress", e.target.value ?? null);
                        dispatch(
                          setCustomer({
                            ...customer,
                            tradingAddress: {
                              ...customer.tradingAddress,
                              street: e.target.value === "" ? null : e.target.value,
                            },
                          }),
                        );
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyPress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CommonTextField
                      label="City: *"
                      name="city"
                      id="wcfCompanyFormCommonRegOrTradeCityInput"
                      value={values.city}
                      inputProps={{ maxLength: 100 }}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                      onChange={(e) => {
                        setFieldValue("city", e.target.value);
                        dispatch(
                          setCustomer({
                            ...customer,
                            tradingAddress: {
                              ...customer.tradingAddress,
                              cityOrTown: e.target.value === "" ? null : e.target.value,
                            },
                          }),
                        );
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyPress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <CommonTextField
                      label="Postcode: *"
                      name="postcode"
                      id="wcfCompanyFormCommonRegOrTradePostcodeInput"
                      error={Boolean(touched.postcode && errors.postcode)}
                      helperText={touched.postcode && errors.postcode}
                      value={values.postcode}
                      onChange={(e) => {
                        setFieldValue("postcode", e.target.value);
                        dispatch(
                          setCustomer({
                            ...customer,
                            tradingAddress: {
                              ...customer.tradingAddress,
                              postcode: e.target.value === "" ? null : e.target.value,
                            },
                          }),
                        );
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyPress}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item xs={12} mt={1}>
                    <TextField
                      select
                      label="Country: *"
                      name="country"
                      id="wcfCompanyFormCommonRegOrTradeCountryInput"
                      value={values.country}
                      onChange={(e) => {
                        setFieldValue("country", e.target.value);
                        dispatch(
                          setCustomer({
                            ...customer,
                            tradingAddress: {
                              ...customer.tradingAddress,
                              country: e.target.value,
                            },
                          }),
                        );
                      }}
                      sx={{ width: "100%" }}>
                      {countries.map(({ label, value }) => (
                        <MenuItem
                          key={value}
                          value={value}
                          id={"wcfCompanyFormCountrySelection" + value}
                          style={getStyles(value, [], theme)}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container display="flex" alignItems="center">
                  <Grid item xs={12}>
                    <FormControl error={Boolean(touched.privacyPolicy && errors.privacyPolicy)}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="privacyPolicy"
                            id="wcfCompanyFormPrivacyPolicyCheckboxInput"
                            checked={values.privacyPolicy}
                            onChange={(e) => {
                              setFieldValue("privacyPolicy", e.target.checked);
                              dispatch(
                                setCustomer({
                                  ...customer,
                                  hasConsentedToPrivacyPolicy: e.target.checked,
                                }),
                              );
                            }}
                          />
                        }
                        label={
                          <Typography>
                            By ticking this box I agree to{" "}
                            <Link
                              href={CONFIG.URLS.PRAETURA_PRIVACY_POLICY}
                              target="_blank"
                              rel="noopener noreferrer"
                              id="wcfCompanyFormPrivacyPolicyCheckboxLink">
                              Praetura's Privacy Policy
                            </Link>
                          </Typography>
                        }
                      />
                      {Boolean(touched.privacyPolicy && errors.privacyPolicy) && (
                        <Typography
                          variant="caption"
                          color="error"
                          id="companyProfileFormPrivacyError">
                          {touched.privacyPolicy && errors.privacyPolicy}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container display="flex" alignItems="center">
                  <Grid item xs={12}>
                    <FormControl error={Boolean(touched.creditCheck && errors.creditCheck)}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="creditCheck"
                            id="wcfCompanyFormCreditCheckCheckboxInput"
                            checked={values.creditCheck}
                            onChange={(e) => {
                              setFieldValue("creditCheck", e.target.checked);
                              dispatch(
                                setCustomer({
                                  ...customer,
                                  hasConsentedToCreditCheck: e.target.checked,
                                }),
                              );
                            }}
                          />
                        }
                        label={
                          <Typography>
                            By ticking this box I consent to Praetura Finance performing a Credit
                            check
                          </Typography>
                        }
                      />
                      {Boolean(touched.creditCheck && errors.creditCheck) && (
                        <Typography
                          variant="caption"
                          color="error"
                          id="companyProfileFormCreditError">
                          {touched.creditCheck && errors.creditCheck}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={0} md={6} />
            </Grid>
          </Card>
        </Box>
        <Box flexGrow={1} />
        <WcfFooter
          displaySaveAndExitButton={true}
          displayPrevButton={true}
          displayNextButton={true}
          showCustomerSubmitText={true}
          handleSubmit={handleSubmit}
          customerData={{
            ...customer,
            vatInformation: {
              vatNumber:
                values.vatNumber ||
                wcfCustomer?.vatInformation.vatNumber ||
                org?.taxInfo?.vatRegistrationNumber,
              isRegisteredForVat: true,
            },
            website: values.website || wcfCustomer?.website || org?.website || null,
            registeredCompanyName:
              values.registeredCompanyName ||
              wcfCustomer?.registeredCompanyName ||
              org?.companyInfo.name ||
              null,
          }}
        />
      </form>
    </Page>
  );
}

export default CompanyProfileForm;
