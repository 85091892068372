import { createReducer } from "deox";

import {
  BankAccountExtended,
  Ledger,
  ReconsentData,
  UserInfoData,
  UserSubscription,
  CardPaymentApp,
  ClearingLedger,
  CoPStatus,
  ERPPlanValue,
  Custodian,
  CardPaymentsSettings,
} from "@APP/types";
import { ErpId } from "@APP/constants";

import { setCardPaymentsCustodians } from "./../actions/auth";
import {
  setAuthenticated,
  setAuthLoading,
  setCardPaymentApp,
  setBankLedgers,
  setReconsentBanks,
  setUserData,
  setUserSubscription,
  setClearingLedger,
  setCoPStatus,
  setUserOrganisationData,
  setCardPaymentsSettings,
  setSettingsHidden,
  isOcmVisible,
  isDashboardVisible,
} from "../actions";

export interface AuthState {
  loading: boolean;
  authenticated: boolean;
  user:
    | null
    | (UserInfoData & {
        erp: ErpId | null;
        erpPlan: ERPPlanValue | null;
        bankAccounts: BankAccountExtended[] | null;
        subscription: UserSubscription | null;
        cardPaymentApp: CardPaymentApp | null;
      });
  bankLedgers: Ledger[];
  clearingLedger: ClearingLedger | null;
  reconsentBanks: ReconsentData[];
  cardPaymentsCustodians: Custodian[];
  cardPaymentsSettings: CardPaymentsSettings | null;
  CoPStatus?: CoPStatus;
  settingsHidden?: boolean;
  isOcmVisible?: boolean;
  isDashboardVisible?: boolean;
}

const defaultState: AuthState = {
  loading: false,
  authenticated: false,
  user: null,
  bankLedgers: [],
  clearingLedger: null,
  cardPaymentsCustodians: [],
  cardPaymentsSettings: null,
  reconsentBanks: [],
  settingsHidden: false,
  isOcmVisible: false,
  isDashboardVisible: false,
};

const authReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setAuthLoading, (state, { payload }) => ({ ...state, loading: payload })),
  handleAction(setAuthenticated, (state, { payload }) => ({ ...state, authenticated: payload })),
  handleAction(setSettingsHidden, (state, { payload }) => ({ ...state, settingsHidden: payload })),
  handleAction(isOcmVisible, (state, { payload }) => ({
    ...state,
    isOcmVisible: payload,
  })),
  handleAction(isDashboardVisible, (state, { payload }) => ({
    ...state,
    isDashboardVisible: payload,
  })),
  handleAction(setUserData, (state, { payload }) => ({ ...state, user: payload })),
  handleAction(setBankLedgers, (state, { payload }) => ({ ...state, bankLedgers: payload })),
  handleAction(setClearingLedger, (state, { payload }) => ({
    ...state,
    clearingLedger: payload,
  })),
  handleAction(setReconsentBanks, (state, { payload }) => ({
    ...state,
    reconsentBanks: payload,
  })),
  handleAction(setCardPaymentApp, (state, { payload }) => ({
    ...state,
    user: {
      ...state.user!,
      cardPaymentApp: payload,
    },
  })),
  handleAction(setCardPaymentsSettings, (state, { payload }) => ({
    ...state,
    cardPaymentsSettings: payload,
  })),
  handleAction(setUserSubscription, (state, { payload }) => ({
    ...state,
    user: {
      ...state.user!,
      subscription: payload,
    },
  })),
  handleAction(setUserOrganisationData, (state, { payload }) => ({
    ...state,
    user: {
      ...state.user!,
      org: payload,
    },
  })),
  handleAction(setCoPStatus, (state, { payload }) => ({
    ...state,
    CoPStatus: payload,
  })),
  handleAction(setCardPaymentsCustodians, (state, { payload }) => ({
    ...state,
    cardPaymentsCustodians: payload,
  })),
]);

export default authReducer;
