import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import {
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { green, grey, orange, red } from "@mui/material/colors";

import { TabsName } from "@APP/constants";
import {
  fetchUserSubscription,
  getBankAccounts,
  getSubscription,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  RTP,
  RTPStatus,
  SubscriptionFeatureTypes,
  SubscriptionPlan,
  SubscriptionStatuses,
} from "@APP/types";
import {
  checkSubscriptionActivationAvailable,
  getCurrencySymbol,
  formatDisplayedDate,
} from "@APP/utils";
import { getServiceChargeRtps } from "@APP/services/api";
import CONFIG from "@APP/config";
import { useAlert } from "@APP/hooks";

const useStyles = makeStyles((theme) => ({
  month: {
    color: grey[600],
  },
  mainContent: {
    margin: theme.spacing(3, 2),
  },
  active: {
    color: green["A700"],
  },
  pending: {
    color: orange[600],
  },
  activeContainer: {
    backgroundColor: alpha(green["A700"], 0.15),
  },
  inactive: {
    color: red[600],
  },
  inactiveContainer: {
    backgroundColor: alpha(red[600], 0.15),
  },
  pendingContainer: {
    backgroundColor: alpha(orange[600], 0.15),
  },
  container: {
    "&.MuiCardContent-root": {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      textAlign: "center",
    },
  },
}));

const ACTIVE_STATUSES = [SubscriptionStatuses.Active, SubscriptionStatuses.Trial];

const STATUS_LABELS = {
  [SubscriptionStatuses.Active]: "Active",
  [SubscriptionStatuses.Cancelled]: "Inactive",
  [SubscriptionStatuses.Suspended]: "Suspended",
  [SubscriptionStatuses.Trial]: "Trial",
  [SubscriptionStatuses.PendingCancellation]: "Pending Cancellation",
};

const AccountDetails = ({ subscriptionPlan }: { subscriptionPlan: SubscriptionPlan | null }) => {
  const classes = useStyles();
  const alert = useAlert();
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isSmSize = useMediaQuery(theme.breakpoints.down("md"));
  const subscription = useSelector(getSubscription);
  const [serviceChargeRtps, setServiceChargeRtps] = useState<RTP[]>([]);
  const bankAccounts = useSelector(getBankAccounts);

  // If the user is not subscribed yet and the subscription plan has a free trial, do not display the price.
  const shouldHidePriceInfo = !subscription && subscriptionPlan?.freeTrialDays;

  const isPlanOfCoopUser =
    subscriptionPlan?.price?.amount === "0.00" &&
    CONFIG.FEATURES.SUBSCRIPTIONS.TYPE === SubscriptionFeatureTypes.Flexible;
  const isActivationAvailable = checkSubscriptionActivationAvailable(subscription);
  const isActiveStatus = subscription?.status
    ? ACTIVE_STATUSES.includes(subscription!.status)
    : false;

  useEffect(() => {
    (async () => {
      if (
        subscription &&
        (subscription.status === SubscriptionStatuses.Active ||
          subscription.status === SubscriptionStatuses.Suspended)
      ) {
        try {
          setServiceChargeRtps((await getServiceChargeRtps()).data);
        } catch (e) {
          setServiceChargeRtps([]);
          alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            t("Errors.Subscriptions.Alerts.GetServiceCharge.Message"),
            [{ text: "Cancel" }],
          );
        }
      } else {
        setServiceChargeRtps([]);
      }
    })();
  }, [subscription]);

  const renderSubscriptionAmountContent = () => {
    if (!subscriptionPlan?.price?.amount) return null;

    if (isPlanOfCoopUser) {
      if (subscriptionPlan.id !== subscription?.planId && isActiveStatus) {
        return (
          <Typography color="textSecondary" align="center">
            You now qualify for a free service, please deactivate and reactivate here.
          </Typography>
        );
      }

      return (
        <Typography color="textSecondary" align="center">
          Your service is free of charge.
        </Typography>
      );
    }

    return (
      <>
        <Typography variant="h4" component="span">
          {getCurrencySymbol(subscriptionPlan?.price.currency)}
        </Typography>
        <Typography variant="h1" component="span">
          {subscriptionPlan?.price.amount}
        </Typography>
        <Typography variant="h4" component="span" className={classes.month}>
          /month plus vat
        </Typography>
      </>
    );
  };

  const renderSubscriptionActions = () => {
    const redirectToActivateAccount = async () => {
      const currentSubscription = await dispatch(fetchUserSubscription());

      if (currentSubscription?.status === subscription?.status) {
        history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACTIVATE_ACCOUNT}`);
      }
    };

    const redirectToDeactivateAccount = () => {
      history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.DEACTIVATE_ACCOUNT}`);
    };
    if (isPlanOfCoopUser) {
      if (!isActivationAvailable && subscriptionPlan.id === subscription?.planId) return null;

      return subscriptionPlan.id !== subscription?.planId && !isActivationAvailable ? (
        <>
          <Divider />
          <Box width="100%" p={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={redirectToDeactivateAccount}
              id="accountDetailsDeactivateButton">
              Deactivate Account
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Divider />
          <Box width="100%" p={2}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={redirectToActivateAccount}
              id="accountDetailsActivateButton">
              Activate Account
            </Button>
          </Box>
        </>
      );
    }

    return (
      <>
        <Divider />
        <Tooltip
          title={
            isActivationAvailable && (!bankAccounts || bankAccounts.length === 0)
              ? "You need to link a bank account before you can activate your account"
              : ""
          }
          leaveDelay={200}
          placement="top">
          <div>
            <Box width="100%" p={2}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                disabled={
                  isActivationAvailable
                    ? !bankAccounts || (bankAccounts && bankAccounts.length === 0)
                    : false
                }
                id="accountDetailsDeactivateBtn"
                onClick={
                  isActivationAvailable ? redirectToActivateAccount : redirectToDeactivateAccount
                }>
                {isActivationAvailable ? "Activate Account" : "Deactivate Account"}
              </Button>
            </Box>
          </div>
        </Tooltip>
      </>
    );
  };

  const renderPayServiceChargeContent = () => {
    // Use RTP with "Request Made" status for service charges
    const activeServiceChargeRTP = serviceChargeRtps.find(
      (rtp) => rtp.status === RTPStatus.RequestMade,
    );

    if (activeServiceChargeRTP && activeServiceChargeRTP?.paymentLink) {
      return (
        <>
          <Divider />
          <CardContent>
            <Box textAlign="center">
              <Typography variant="body2">
                {subscription!.status === SubscriptionStatuses.Active
                  ? t("Errors.Subscriptions.Messages.PayService")
                  : t("Errors.Subscriptions.Alerts.UnpaidCharge.Message")}
              </Typography>
            </Box>
          </CardContent>
          <Box p={2} width="100%">
            <Link
              target="_blank"
              href={activeServiceChargeRTP.paymentLink}
              underline="none"
              rel="noopener noreferrer"
              id="accountDetailsPaymentLink">
              <Button fullWidth color="inherit" variant="contained">
                Pay Service Charge
              </Button>
            </Link>
          </Box>
        </>
      );
    }

    return null;
  };

  return (
    <Card elevation={4}>
      <CardHeader title="Account details" subheader="View your account details" />
      <Divider />
      <CardContent className={classes.mainContent}>
        <Box
          display="flex"
          justifyContent="space-around"
          alignItems="center"
          columnGap={4}
          flexDirection={isSmSize ? "column" : "row"}>
          <Box>
            <Card>
              <CardContent
                className={clsx(classes.container, {
                  [classes.activeContainer]: isActiveStatus,
                  [classes.inactiveContainer]:
                    !isActiveStatus &&
                    subscription?.status !== SubscriptionStatuses.PendingCancellation,
                  [classes.pendingContainer]:
                    !isActiveStatus &&
                    subscription?.status === SubscriptionStatuses.PendingCancellation,
                })}>
                <Typography
                  className={clsx({
                    [classes.active]: isActiveStatus,
                    [classes.inactive]:
                      !isActiveStatus &&
                      subscription?.status !== SubscriptionStatuses.PendingCancellation,
                    [classes.pending]:
                      !isActiveStatus &&
                      subscription?.status === SubscriptionStatuses.PendingCancellation,
                  })}
                  variant="h2"
                  component="p">
                  {subscription?.status
                    ? STATUS_LABELS[subscription!.status]
                    : STATUS_LABELS.Cancelled}
                </Typography>
              </CardContent>
            </Card>
          </Box>
          {!shouldHidePriceInfo && (
            <Box>
              <Box display="flex" justifyContent="center" mt={isSmSize ? 3 : 0}>
                {renderSubscriptionAmountContent()}
              </Box>
            </Box>
          )}
        </Box>
        {subscription?.status === SubscriptionStatuses.Trial && subscription?.endDate && (
          <Box mt={4} textAlign="center">
            <Typography variant="body1">
              You are currently in your free trial period, this will end on{" "}
              {formatDisplayedDate(subscription.endDate)}. We will contact you prior to this date
              with information on how to continue to use this service.
            </Typography>
          </Box>
        )}
        {subscription?.status === SubscriptionStatuses.PendingCancellation &&
          subscription?.endDate && (
            <Box mt={4} textAlign="center">
              <Typography variant="body1">
                Your account is scheduled to be cancelled, but you can still use the app until{" "}
                <b>{formatDisplayedDate(subscription.endDate)}</b>. To use the app past this date
                please activate your account.
              </Typography>
            </Box>
          )}
      </CardContent>
      {renderSubscriptionActions()}
      {renderPayServiceChargeContent()}
    </Card>
  );
};

export default AccountDetails;
