import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  IconButton,
  lighten,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import CONFIG from "@APP/config";
import { SecondaryTooltip } from "@APP/components";
import { IMAGES } from "@APP/assets";
import { AppLocalStorage, LocalStorageKey } from "@APP/services";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: "8rem",

    [theme.breakpoints.up("sm")]: {
      width: "12rem",
    },
  },
  downloadBlock: {
    width: "fit-content",
    margin: "auto",
  },
  downloadContentContainer: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
  },
}));

const AppStorePromoSection = ({
  showGoToMobileAppButton = true,
}: {
  showGoToMobileAppButton?: boolean;
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDisplaySizeLessMd = useMediaQuery(theme.breakpoints.down("lg"));

  const [showTooltip, setShowTooltip] = useState(false);

  const onHideTooltip = () => setShowTooltip(false);

  const handleClickOnGoMobileApp = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const token = AppLocalStorage.getItem(LocalStorageKey.authToken);

    if (isMobile) {
      window.open(`${CONFIG.URLS.REDIRECT_URL_TO_MOBILE!}?token=${token}`, "_blank");
    } else {
      setShowTooltip(true);
    }
  };

  return (
    <Box>
      {CONFIG.URLS.REDIRECT_URL_TO_MOBILE && showGoToMobileAppButton && (
        <Box mt={3} mb={3}>
          <ClickAwayListener onClickAway={onHideTooltip} id="appStorePromoClickAwayListener">
            <SecondaryTooltip
              arrow
              placement="bottom"
              title={
                <Typography>
                  {t("Errors.Admin.Messages.MobileApp", {
                    PRODUCT_NAME: CONFIG.PRODUCT_NAME,
                  })}
                </Typography>
              }
              open={showTooltip}
              id="appStorePromoSectionTooltip">
              <Button
                color="primary"
                variant="contained"
                size="large"
                fullWidth
                onClick={handleClickOnGoMobileApp}
                id="appStorePromoSectionButton">
                Go to mobile app
              </Button>
            </SecondaryTooltip>
          </ClickAwayListener>
        </Box>
      )}
      <Box>
        {CONFIG.URLS.IOS_MOBILE_APPLICATION_URL || CONFIG.URLS.ANDROID_MOBILE_APPLICATION_URL ? (
          <Card className={classes.downloadBlock}>
            <CardContent className={classes.downloadContentContainer}>
              <Typography
                color="textPrimary"
                align="center"
                variant="h5"
                id="appStorePromoDownloadAppText">
                If you haven't already downloaded and installed our mobile app please do so now.
                Once installed you can login and start requesting payments.
              </Typography>
              <Box
                display="flex"
                flexDirection={isDisplaySizeLessMd ? "column" : "row"}
                justifyContent="center"
                mt={3}>
                {CONFIG.URLS.IOS_MOBILE_APPLICATION_URL && (
                  <IconButton
                    disableRipple={true}
                    onClick={() => window.open(CONFIG.URLS.IOS_MOBILE_APPLICATION_URL, "_blank")}
                    size="large"
                    id="appStorePromoAppStoreIconButton">
                    <img
                      alt="ios"
                      src={IMAGES.IOS_APP_STORE}
                      className={classes.iconButton}
                      id="appStorePromoAppStoreIcon"
                    />
                  </IconButton>
                )}
                {CONFIG.URLS.ANDROID_MOBILE_APPLICATION_URL && (
                  <IconButton
                    disableRipple={true}
                    onClick={() =>
                      window.open(CONFIG.URLS.ANDROID_MOBILE_APPLICATION_URL, "_blank")
                    }
                    size="large"
                    id="appStorePromoPlayStoreIconButton">
                    <img
                      alt="google"
                      src={IMAGES.GOOGLE_PLAY}
                      className={classes.iconButton}
                      id="appStorePromoPlayStoreIcon"
                    />
                  </IconButton>
                )}
              </Box>
            </CardContent>
          </Card>
        ) : null}
      </Box>
    </Box>
  );
};

export default AppStorePromoSection;
