import formUrlEncoded from "form-urlencoded";
import i18next from "i18next";

import CONFIG from "@APP/config";
import {
  LoginData,
  RegistrationBody,
  UserInfoData,
  BusinessContactStatus,
  UserOrganisation,
  UserPermissionApiResponse,
} from "@APP/types";

import request from "../request";
import { HEADERS } from "../constants";

export const login = async (email: string, password: string) => {
  try {
    const response = await request<LoginData>(
      {
        method: "POST",
        url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/public/login`,
        data: { username: email, password },
      },
      { auth: false },
    );
    return response.data;
  } catch (error) {
    if (error?.response?.status === 500) {
      throw new Error({
        ...error,
        message: i18next.t("Errors.PreAuth.Alerts.Login.Message"),
      });
    }
    throw error;
  }
};

export const logout = async (token: string) =>
  await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/logout`,
    headers: {
      [HEADERS.AUTH_HEADER]: token,
    },
  });

export const getUserInfo = async () => {
  const response = await request<UserInfoData>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/userInfo`,
  });

  return response.data;
};

export const refreshToken = async () => {
  const response = await request({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/token_refresh`,
  });
  return response.data;
};

export const requestPasswordReset = async (email: string, captchaToken: string) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/password_reset`,
    data: { username: email },
    headers: {
      "Recaptcha-Response": captchaToken,
    },
  });
  return response.data;
};

export const resetPassword = async (code: string, password: string) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/password_reset/confirm`,
    params: { code },
    data: { password },
  });

  return response.data;
};

export const verifyResetPasswordCode = async (code: string) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/password_reset/check_code`,
    params: { code },
  });

  return response.data;
};

export const verifyEmail = async (email: string) => {
  const response = await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/register`,
    data: formUrlEncoded({ action: "verify_email", email }),
  });

  return response.data;
};

export const register = (body: RegistrationBody) => {
  return request(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/register`,
      data: formUrlEncoded(body),
    },
    { auth: false },
  );
};

export const createUser = (email: string, phone: string, password: string) => {
  return request(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/users`,
      data: { email, phone, password },
    },
    { auth: false },
  );
};

export const sendVerificationEmail = (email: string) => {
  return request<void>(
    {
      method: "POST",
      url: `${CONFIG.API.ROOT_URL}/${
        CONFIG.API.VERSION
      }/onboarding/send_verification_email/${encodeURIComponent(email)}`,
    },
    { auth: false },
  );
};

export const updateContactDetails = async (
  orgId: string,
  name: string,
  email: string,
  telephone: string,
) => {
  return await request({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/organisations/${orgId}/contact`,
    data: {
      name,
      email,
      telephone,
    },
  });
};

export const getBusinessContactEmail = async (orgId: string) => {
  const response = await request<BusinessContactStatus>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/organisations/${orgId}/contact/status`,
  });
  return response.data;
};

export const resendContactVerificationEmail = async (orgId: string) => {
  return await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/organisations/${orgId}/contact/resend_verification_email`,
  });
};

export const updateOrganisationDetails = async (orgId: string, organisation: UserOrganisation) => {
  return await request({
    method: "PUT",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/organisations/${orgId}`,
    data: {
      ...organisation,
    },
  });
};

export const uploadOrganisationLogo = async (formData: FormData) => {
  const response = await request<string>({
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/logo`,
    data: formData,
  });

  return response.data;
};

export const acceptTermsAndConditions = async () =>
  await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/user-mgmt/users/terms-and-conditions`,
  });

export const onboardingAcceptTermsAndConditions = async (email: string) =>
  await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/onboarding/users/${email}/terms-and-conditions`,
  });

export const retrieveUserPermission = async (
  user?: string,
  organization?: string,
  permissions?: { resource: string; action: string }[],
): Promise<{ data: UserPermissionApiResponse }> => {
  return await request({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/authz/users/multiple`,
    data: { user, organization, access: permissions },
  });
};

export default {
  login,
  getUserInfo,
  requestPasswordReset,
  verifyEmail,
  register,
  createUser,
  sendVerificationEmail,
  updateContactDetails,
  getBusinessContactEmail,
  resendContactVerificationEmail,
  acceptTermsAndConditions,
  onboardingAcceptTermsAndConditions,
  retrieveUserPermission,
};
