import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { InvoiceType, Payable, Receivable } from "@APP/types";
import { SCREEN_PATHS } from "@APP/navigation";
import { capitalize } from "@APP/utils";
import { getUser, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { EmptyList } from "@APP/components";
import { useFeatureGateway } from "@APP/hooks";
import CONFIG from "@APP/config";

import BodyTableContent from "./BodyTableContent";
import DueContent from "./DueContent";
import OverdueContent from "./OverdueContent";

type Props = {
  data: Array<Receivable | Payable> | null;
  dataType: InvoiceType;
  headerTableTitles: Array<{ title: string; testId?: string }>;
  emptyListMessage: string;
};

const TableInvoices = ({ data, dataType, headerTableTitles, emptyListMessage }: Props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const gateway = useFeatureGateway();

  const user = useSelector(getUser);

  const getViewMoreLink = async () => {
    dispatch(showLoader());

    const paymentСreationAllowed = await gateway.createPayment({
      checkClearingLedger: CONFIG.FEATURES.SETUP.CLEARING_LEDGER || false,
    });

    dispatch(hideLoader());

    if (!paymentСreationAllowed) {
      return;
    }

    switch (dataType) {
      case InvoiceType.Receivables:
        return history.push(SCREEN_PATHS.RECEIVABLES_LIST);
      case InvoiceType.Payables:
        return history.push(SCREEN_PATHS.PAYABLES_LIST);
    }
  };

  return (
    <>
      {data && data.length ? (
        <>
          <Box mb={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <OverdueContent dataType={dataType} data={data} />
              </Grid>
              <Grid item xs={6}>
                <DueContent data={data} dataType={dataType} />
              </Grid>
            </Grid>
          </Box>
          <Box minHeight={345}>
            <TableContainer data-testid={`table-body-${dataType}`} id={`tableBody${dataType}`}>
              <Table>
                <caption className="visuallyHidden">{capitalize(dataType)} table</caption>
                <TableHead>
                  <TableRow
                    date-testid={`table-header-row-${dataType}`}
                    id={`tableHeaderRow${dataType}`}>
                    {headerTableTitles.map((header) => (
                      <TableCell
                        key={header.title}
                        align="center"
                        data-testid={`table-header-cell-${dataType}${
                          header.testId ? `-${header.testId}` : ""
                        }`}
                        id={`tableHeaderCell${dataType}${header.testId ? `${header.testId}` : ""}`}>
                        <Typography variant="h6" component="p" color="primary">
                          {header.title}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <BodyTableContent data={data.slice(0, 5)} dataType={dataType} user={user} />
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        <Box display="flex" flexGrow={1} minHeight={200}>
          <EmptyList
            message={emptyListMessage}
            emptyListDataTestId={`empty-table-text-${dataType}`}
            id={`emptyTableText${dataType}`}
          />
        </Box>
      )}
      <Grid container justifyContent="center">
        <Grid item md={6}>
          <Box textAlign="center" mt={3}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              data-testid={`view-more-button-${dataType}`}
              id={`viewMoreButton${dataType}`}
              onClick={getViewMoreLink}>
              {dataType === InvoiceType.Receivables ? "View invoices" : "View supplier invoices"}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TableInvoices;
