import axios, { AxiosRequestConfig } from "axios";
import * as Sentry from "@sentry/react";
import { jwtDecode, JwtPayload } from "jwt-decode";

import { history, SCREEN_PATHS } from "@APP/navigation";

import AppLocalStorage, { LocalStorageKey } from "../AppLocalStorage";
import AppSessionStorage from "../SessionStorage";
import { HEADERS } from "./constants";
import { TOKEN_EXPIRED_ERROR_CODE } from "./errorCodes";

type RequestOptions = {
  auth?: boolean;
  isRequestToPay?: boolean;
  logError?: boolean;
  contain?: boolean;
};

interface Organisation extends JwtPayload {
  org_id: string;
  username: string;
}

const REQUEST_DEFAULT_OPTIONS: RequestOptions = {
  auth: true,
  logError: true,
  contain: false,
};

/**
 * HTTP client wrapper around "axios" API.
 * @param config "axios" config: https://github.com/axios/axios#request-config
 * @param options
 *  "auth" - include auth header in the request;
 */
const request = async <T = any>(config: AxiosRequestConfig, options = REQUEST_DEFAULT_OPTIONS) => {
  options = { ...REQUEST_DEFAULT_OPTIONS, ...options };
  let headers = config.headers || {};

  // Attach auth token header based on options:
  if (options.auth) {
    const bankifiToken = AppLocalStorage.getItem(LocalStorageKey.authToken);
    if (bankifiToken) headers = { ...headers, [HEADERS.AUTH_HEADER]: bankifiToken };
  }

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const errorCode = error.response?.data?.errorCode;

      if (errorCode === TOKEN_EXPIRED_ERROR_CODE) {
        AppSessionStorage.setItem("modal.dismissed", "true");
        AppLocalStorage.removeItem(LocalStorageKey.authToken);
        history.replace(SCREEN_PATHS.LOGIN);
        return;
      }

      return Promise.reject(error);
    },
  );
  if (options?.contain) {
    const token = AppLocalStorage.getItem(LocalStorageKey.authToken);
    const orgId = jwtDecode<Organisation>(token as string).org_id;
    const username = jwtDecode<Organisation>(token as string).username;
    headers = {
      ...headers,
      [HEADERS.BANKIFI_ORGID]: orgId,
      [HEADERS.BANKIFI_USERNAME]: username,
    };
  }

  if (options.logError) {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log(`API error, ${JSON.stringify(error?.response?.data ?? error)}`);
        Sentry.captureException(error);

        return Promise.reject(error);
      },
    );
  }

  return axios.request<T>({ ...config, headers });
};

export default request;
