import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  fetchUserData,
  getBankAccounts,
  getErpId,
  getUser,
  logout,
  softLogout,
  useAppDispatch,
} from "@APP/redux";
import { AppRouter, getRoutes, SCREEN_PATHS } from "@APP/navigation";
import { AppLoader } from "@APP/components";
import { AppLocalStorage, LocalStorageKey } from "@APP/services";
import { ErpId } from "@APP/constants";
import { useAccessPermission, useAlert, useHandleErrorCodes } from "@APP/hooks";
import {
  BANK_CONSENT_EXPIRED_ERROR_CODE,
  COP_HANDLED_ERROR_CODES,
  SAGE_SUBSCRIPTION_NOT_FOUND,
} from "@APP/services/api";
import { errorCodeString, getErrorMessageByErrorCode, timeout } from "./utils";

import CONFIG from "./config";
import { SubscriptionFeatureTypes } from "./types";

export const RootComponent = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { fetchAllPermissions } = useAccessPermission();
  const alert = useAlert();
  const { t } = useTranslation();

  const { token: redirectToken } = queryString.parse(history.location.search ?? "") as {
    token: string;
  };

  const erpId = useSelector(getErpId);
  const bankAccounts = useSelector(getBankAccounts);
  const routes = getRoutes(bankAccounts || [], erpId!);
  const user = useSelector(getUser);

  const [appReady, setAppReady] = useState(false);

  const localStorageHandler = useCallback((e: StorageEvent) => {
    const appStoreKey = e.key as LocalStorageKey;
    if (appStoreKey === LocalStorageKey.authToken && !e.newValue) {
      dispatch(logout());
    }
  }, []);

  /**
   * Reloads page in case it's taken from bfcache
   */
  const onPageShowHandler = useCallback((e: PageTransitionEvent) => {
    if (e.persisted) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    (async () => {
      window.addEventListener("storage", localStorageHandler);
      window.addEventListener("pageshow", onPageShowHandler);

      try {
        setAppReady(false);

        let authToken = AppLocalStorage.getItem(LocalStorageKey.authToken);

        if (redirectToken) {
          authToken = redirectToken;
          AppLocalStorage.setItem(LocalStorageKey.authToken, redirectToken);
        }

        if (authToken && !user) {
          const userData = await dispatch(fetchUserData());

          let registrationCompleted = !!userData.bankAccounts?.length;

          if (CONFIG.FEATURES.SUBSCRIPTIONS.TYPE !== SubscriptionFeatureTypes.None) {
            registrationCompleted = registrationCompleted && !!userData.subscription;
          }

          AppLocalStorage.setItem(
            LocalStorageKey.registrationCompleted,
            registrationCompleted ? "true" : "false",
          );

          await fetchAllPermissions(userData);

          // https://bankifi.atlassian.net/browse/BN-3169
          // Throw error message on sage subscription failure
          if (userData?.errorFromSageAPRequest) {
            const errorData = userData.errorFromSageAPRequest.response?.data;
            if (SAGE_SUBSCRIPTION_NOT_FOUND === errorData.errorCode) {
              toast.error(
                getErrorMessageByErrorCode(errorData.errorCode, undefined, undefined, true),
                {
                  position: "top-right",
                  autoClose: 15000,
                },
              );
            }
          }
        }

        if (window.location.pathname === SCREEN_PATHS.APP_ERROR) {
          history.replace(CONFIG.NAVIGATION.MAIN_PAGE);
        }
      } catch (error) {
        const errorData = error.response?.data;
        const errorCode = errorCodeString(errorData?.errorCode);

        if (redirectToken) {
          dispatch(softLogout());

          history.replace(SCREEN_PATHS.LOGIN);
          // Artificial delay to allow the logout to complete and properly display alert
          await timeout(500);

          return alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            t("Sorry, we were unable to open your settings. Please try to login.") + errorCode,
          );
        }

        const isHandled = handleErrorCodes(errorData?.errorCode, {
          errorCodes: [...COP_HANDLED_ERROR_CODES, BANK_CONSENT_EXPIRED_ERROR_CODE],
          errorMessage: errorData?.errorMessage,
        });

        if (!isHandled && AppLocalStorage.getItem(LocalStorageKey.authToken)) {
          history.push({
            pathname: SCREEN_PATHS.APP_ERROR,
            state: { errorCode: errorData?.errorCode },
          });
        }
      } finally {
        setAppReady(true);
      }
    })();

    return () => {
      window.removeEventListener("storage", localStorageHandler);
      window.removeEventListener("pageshow", onPageShowHandler);
    };
  }, []);

  useEffect(() => {
    if (
      window.location.pathname === SCREEN_PATHS.SETUP_ACCOUNTING_PACKAGE &&
      !window.location.search &&
      erpId &&
      erpId !== ErpId.INTERNAL
    ) {
      history.push(SCREEN_PATHS.DASHBOARD);
    }
  }, [erpId]);

  return <>{appReady ? <AppRouter routes={routes} /> : <AppLoader />}</>;
};

export default RootComponent;
