import { AdditionalData, SchemesNames } from "@APP/types/paybles";
import { CompanyType } from "@APP/constants";
import { ERROR_CODES_LIST } from "@APP/services/api";

export * from "@APP/types/forecast";

export interface GetCompanyByKeywordType {
  company_number: string;
  company_status: string;
  company_type: string;
  date_of_cessation: string;
  date_of_creation?: string;
  description: string;
  title: string;
}

export interface GetDirectorsByRegistrationNumberType {
  address: {
    address_line_1: string | null;
    address_line_2: string | null;
    care_of: string | null;
    country: string | null;
    locality: string | null;
    po_box: string | null;
    postal_code: string | null;
    premises: string | null;
    region: string | null;
  };
  validated_name: {
    first_name: string | null;
    last_name: string | null;
    middle_name: string | null;
  };
  appointed_on: string | null;
  date_of_birth: {
    date: string | null;
    month: string | null;
    year: string | null;
  } | null;
  name: string;
  officer_role: string | null;
  resigned_on?: string;
}

export interface CreateOrganisationSoleTrader {
  name: {
    first: string;
    last: string;
  };
  companyName: string;
  orgType: CompanyType;
}

export interface CreateOrganisationCompanyType {
  companyName: string;
  companyNumber: string;
  orgType: CompanyType;
}

export interface LoginData {
  token: string;
  emailVerified: boolean;
  phoneVerified: boolean;
}

export type Customer = {
  email?: string | undefined;
  mobile: string | undefined;
  name?: string;
} & AddressInformation;

export interface UserInfoData {
  username: string;
  phone: string;
  orgName?: string;
  roles: string[];
  org: null | UserOrganisation;
  emailVerified: boolean;
  businessEmailVerified: boolean;
  termsAndConditionsAccepted: boolean;
}

export interface UserOrganisation {
  id: string;
  name: string;
  companyInfo: ContactInfo;
  primaryContact: ContactInfo;
  otherContacts: any[];
  taxInfo: {
    vatRegistrationNumber?: string;
    utrNumber: string;
    defaultVatRate: string;
    defaultCurrency: string;
  };
  SIC: string[];
  logo: string;
  orgType: string;
  pricingPlan: string;
  registrationDate?: string;
  businessContact?: ContactDetails;
  website?: string;
}

export interface ContactDetails {
  name: string;
  email: string;
  telephone: string;
}

export interface ContactInfo extends ContactDetails {
  id: string;
  address: {
    lines: string[];
    postcode: string;
    city: string;
    country: string;
    state?: string;
  };
}

export interface BusinessContactStatus {
  email: string;
  verified: boolean;
}

export interface ERPOrganisation {
  entityDetails: {
    source: string;
    externalId: string;
  };
  name: string;
  legalName: string;
  baseCurrency: string;
  companyNumber: string;
  vatNumber: string;
  address: Address;
  industryCode: string;
  contact: Contact;
  bankAccount: string;
  financialData: {
    turnoverPerYear: string;
    invoicesPerYear: string;
    creditNotesPerYear: string;
    creditNotesToInvoicesPct: string;
    creditNotesValueToTurnoverPct: string;
  };
}

export enum ERPPlanValue {
  START = "start",
  STANDARD = "standard",
  PLUS = "plus",
}

export interface Contact {
  id?: ResourceId;
  name: string;
  email?: string;
  billingAddress?: Address;
  shippingAddress?: Address;
  vatRate?: VATRate;
  mobile?: string;
  bankDetails?: BankDetails;
}

export type BankDetails = {
  identification: string;
  schemeName: string;
};

export type ResourceId = {
  source?: string;
  externalId: string;
};

export type Address = {
  addressLines?: Array<string | undefined>;
  postcode?: string;
  city?: string;
  countryId?: string;
  state?: string;
};

export enum RegistrationActions {
  REGISTER_EMAIL = "register_email",
  VERIFY_EMAIL = "verify_email",
  REGISTER_PHONE = "register_phone",
  VERIFY_PHONE = "verify_phone",
}

export interface RegistrationBody {
  action: RegistrationActions;
  email?: string;
  phone?: string;
  code?: string;
}

export interface Custodian {
  country: string;
  features: CustodianFeatures[];
  fullName: string;
  id: string;
  logo: string;
  shortName: string;
  type: string;
  website: string;
  tags?: string[];
  description?: string;
  provider: string;
}

export enum CustodianFeatures {
  ACCOUNT_INFORMATION = "AccountInformation",
  BALANCES = "Balances",
  TRANSACTIONS = "Transactions",
  SINGLE_IMMEDIATE_PAYMENT = "SingleImmediatePayment",
  BULK_PAYMENT = "BulkPayment",
}
export interface BankAccount {
  bankId: string;
  accountId: string;
  currency: string;
  nickname: string;
  account: AccountOfBank;
  servicer: {
    schemeName: string;
    identification: string;
  };
  accountType: string;
  accountSubType: string;
  additionalData: AdditionalData;
}

export enum LedgerType {
  Clearings = "Clearings",
  Bank = "Bank",
}

export interface Ledger {
  bankDetails: { scheme: string; accountNumber: string };
  description: string;
  additionalAccountId?: string;
  ledgerId: string;
  ledgerType: LedgerType;
  linkId: string;
}

export interface ClearingLedger {
  bankDetails: { scheme: string; accountNumber: string };
  description: string;
  ledgerId: string;
  name?: string;
}

export interface BankAccountExtended extends BankAccount {
  // balance is optional on CoP accounts as it is not returned by the API
  balance?: Amount;
  bankInfo: Custodian;
}

export type CustodianType = "Bank" | "AccountingPackage" | "CardPaymentProvider";

export type ERPConsentInitData = {
  redirectUrl?: string;
  consentId: string;
};

export type ERPConsentData = {
  consentId: string;
  context: {
    custodianId: string;
    customerId: string;
    status: string;
    created: string;
    consentType: string;
    externalId: string;
  };
  consentBody: string;
};

export interface ErpBanksAccountsResponse {
  data: ErpBanksAccounts[];
}

export interface ErpBanksAccounts {
  accountScheme: string;
  accountId: string;
  accountNumber: string;
  accountName: string;
}

export type CustomerType = {
  entityContact: Contact;
  personContact: Contact;
  companyNumber: string;
  vatNumber: string;
  billingAddress?: Address;
  shippingAddress?: Address;
};

export type BankConsentData = {
  consentId: string;
  context: {
    custodianId: string;
    customerId: string;
    status: string;
    created: string;
    expires: string;
    consentType: string;
    externalId: string;
  };
  consentBody: string;
};

export interface ReconsentData {
  connectionId: string;
  consentId: string;
  custodianId: string;
  custodianName: string;
}

export interface BankConsentLocalData {
  consentId: string;
  bankId: string;
  bankName: string;
  sub: string;
  provider: string;
}

export type BankConsentInitData = {
  redirectUrl: string;
  consentId: string;
};

export type AccountOfBank = {
  schemeName: SchemesNames;
  identification: string;
  name: string;
  secondaryIdentification?: string;
};

export type VATRate =
  | "ECServicesZeroRated"
  | "ReverseChargeServices"
  | "ReverseCharge"
  | "ECGoodsZeroRated"
  | "Standard"
  | "ReverseChargePhonesAndComputerChips"
  | "ExemptFromVAT"
  | "ECGoodsStandard"
  | "ZeroRated"
  | "ECServicesStandard"
  | "Reduced"
  | "NoVATApplicable";

export type InvoiceStatus = "Draft" | "Approved" | "Void" | "Sent" | "Paid" | "PartiallyPaid";

export interface InvoiceLineItem {
  id: string;
  amountTaxExclusive: Amount;
  category: string;
  description: string;
  vatRate: VATRate;
  ledgerCode?: number;
}

export type Invoice = {
  entityDetails: {
    source: string;
    externalId: string;
  };
  lineItems: InvoiceLineItem[];
  dateTimeIssued: Date;
  dueDateTime: Date;
  reference: string;
  remainingAmountTaxInclusive: Amount;
  remainingAmountTaxExclusive: Amount;
  totalAmountTaxExclusive: Amount;
  totalAmountTaxInclusive: Amount;
  paidDate: string;
  plannedPaymentDate: string;
  status: InvoiceStatus;
  attachments?: [
    {
      id: string;
      name: string;
      uri: string;
      contentType: string;
      createdAt: string;
    },
  ];
  notes: string[];
  name: string;
  description: string;
};

export type TaxCategory = {
  name: string;
  amount: Amount;
};

export type Receivable = Invoice & {
  id: string;
  customerContact: Contact;
  invoiceType: TypeInvoice.Receivable;
  remainingAmountTaxExclusive: Amount;
  remainingAmountTaxInclusive: Amount;
  taxCategories?: TaxCategory[];
};

export type AddressInformation = {
  address?: string;
  addressLine2?: string;
  state?: string;
  city?: string;
  zipCode?: string;
};

export type ReceivableWithFailureReason = Receivable & { failureReason?: string };

export type Payable = Invoice & {
  supplierContact: Contact;
  debtorContact: Contact;
  invoiceType: TypeInvoice.Payable;
  remainingAmountTaxExclusive: Amount;
  remainingAmountTaxInclusive: Amount;
};

export type RTP = {
  amount: Amount;
  channel: RTPDeliveryChannel;
  customer: Contact;
  details: {
    externalId: string;
    source: string;
  };
  id: string;
  scheduledDates?: string[];
  status: RTPStatus;
  supplier: {
    SIC: string[];
    companyInfo: {
      email: string;
      id: string;
      name: string;
      telephone: string;
    };
    name: string;
    otherContacts: [];
    primaryContact: {
      id: string;
      name: string;
      email?: string;
      telephone?: string;
    };
    taxInfo: {
      defaultCurrency: string;
      defaultVatRate: string;
      utrNumber: string;
      vatRegistrationNumber?: string;
    };
  };
  supplierBankDetails: {
    accountNumber: string;
    scheme: string;
    bankId: string;
  };
  receivable: {
    lineItems: Receivable["lineItems"];
    dueDateTime: Receivable["dueDateTime"];
    dateTimeIssued: Receivable["dateTimeIssued"];
    reference: Receivable["reference"];
    customerEmail: string;
    customerMobile: string;
  };
  deliveryDates?: string[];
  reminders?: {
    channel: string;
    enabled: boolean;
    tpe: string;
  }[];
  standingOrder?: StandingOrder;
  paymentLink?: string;
  cardPaymentProvider?: string;
};

export enum RTPStatus {
  RequestMade = "RequestMade",
  PaymentSent = "PaymentSent",
  PaymentReceived = "PaymentReceived",
  Declined = "Declined",
  Created = "Created",
  Scheduled = "Scheduled",
  Cancelled = "Cancelled",
  InProgress = "InProgress",
}

export enum RTPDeliveryChannel {
  "Email" = "Email",
  "Sms" = "Sms",
  "OwnSms" = "OwnSms",
  "FaceToFace" = "FaceToFace",
  "WhatsApp" = "WhatsApp",
}

export type DueDate = "Overdue" | "DueToday";

export type RTPQueryFilter = {
  dueDate?: DueDate;
  status?: keyof typeof RTPStatus | (string & {});
};

export interface RTPListFilter {
  key: string;
  label: string;
  queryParams?: RTPQueryFilter;
}

export type ReceivablesQueryFilter = {
  status?: "UNPAID";
};

/**
 * Common pagination options used across API methods.
 */
export interface RequestPagination {
  page?: number;
  entries?: number;
}

export interface Amount {
  amount: string;
  currency: string;
}

export enum SortBy {
  dueDate = "due_date",
  amount = "amount",
  issueDate = "issue_date",
  customerName = "customer_name",
  status = "status",
}

export interface RequestSorting {
  sort_type?: SortType;
  sort_by?: SortBy;
}

export enum SortType {
  asc = "asc",
  desc = "desc",
}

export enum SortingAriaType {
  asc = "ascending",
  desc = "descending",
}

/**
 * A set of pagination links returned in the API response.
 */
export interface ResponsePagination {
  self?: string;
  first?: string;
  prev?: string;
  next?: string;
  last?: string;
}

export enum InvoiceType {
  Payables = "payables",
  Receivables = "receivables",
}

export enum InvoiceStatusFilterType {
  Unpaid = "UNPAID",
}

export type ExternalLedger = Partial<{
  code: string;
  description: string;
  id: string;
}> | null;

export type VatRate = {
  name: string;
  rate: number;
  id: string;
};

export type VatRateLineItem = {
  numericalValue: number;
  requestValue: string;
  label: string;
  value: string;
};

export type LineItemType = {
  id?: string | number[];
  ledgerCode?: string;
  externalLedger?: ExternalLedger;
  vatRate: string | "";
  pickerVatRate?: VatRateLineItem;
  amountTaxExclusive?: {
    amount: number | string;
    currency: string;
  };
  description?: string;
  quantity?: string;
  unitPrice?: string;
  productService?: ProductOrService;
};

export type ProductOrService = {
  details: ResourceId;
  name: string;
  description?: string;
  unitPrice?: number;
  vatRate?: { name?: string; id?: string };
  account?: ExternalLedger;
};

export interface ICustomerReceivablesDetails {
  id: string;
  email: string;
  name: string;
  reference: string;
  amount: string;
  currency: string;
  dueDateTime: Date;
}

export type InvoicePayload = {
  customer: Contact;
  reference?: string;
  lineItems: LineItemType[];
  dateTimeIssued: Date;
  dueDateTime: Date;
  features?: string[];
};

export enum SubscriptionStatuses {
  Active = "Active",
  Cancelled = "Cancelled",
  Trial = "Trial",
  Suspended = "Suspended",
  PendingCancellation = "PendingCancellation",
}
export interface UserSubscription {
  id: string;
  planId: string;
  status: SubscriptionStatuses;
  price: Amount;
  plan?: SubscriptionPlan;
  isPlanFree?: boolean;
  endDate?: string;
}

export type UserSubscriptionResponse = Omit<UserSubscription, "plan" | "isPlanFree">;

export interface SubscriptionPlan {
  id: string;
  freeTrialDays: number;
  periodDays: number;
  price: {
    amount: string;
    currency: string;
  };
  available: Boolean;
}

export enum TypeInvoice {
  Payable = "Payable",
  Receivable = "Receivable",
}

export interface StandingOrder {
  id?: string;
  reference: string;
  frequency: InstalmentFrequency;
  numberOfPayments?: number;
  firstPaymentAmount: Amount;
  recurringPaymentAmount?: Amount;
  finalPaymentAmount?: Amount;
  firstPaymentDate?: string;
  recurringPaymentDate?: string;
  finalPaymentDate?: string;
}

export interface StandingOrderDetails {
  id?: string;
  bankId: string;
  frequency: InstalmentFrequency;
  reference?: string;
  numberOfPayments?: number;
  firstPaymentDateTime: string;
  recurringPaymentDateTime?: string;
  finalPaymentDateTime?: string;
  firstPaymentAmount: Amount;
  recurringPaymentAmount?: Amount;
  finalPaymentAmount?: Amount;
  creditAccount: AccountOfBank;
  debtorAccount?: AccountOfBank;
  supplementaryData?: string;
  instructionIdentification?: string;
  paymentType?: "StandingOrder";
}

export interface StandingOrderResponse {
  consentId: string;
  paymentId?: string;
  status: InstalmentStatus;
  payment: StandingOrderDetails;
  redirectUrl?: string;
}

export enum InstalmentFrequency {
  Daily = "Daily",
  Weekly = "Weekly",
  Monthly = "Monthly",
}

export enum InstalmentStatus {
  Pending = "Pending",
  Received = "Received",
  Rejected = "Rejected",
  Accepted = "Accepted",
  Settled = "Settled",
  AwaitingAuthorisation = "AwaitingAuthorisation",
  AwaitingUpload = "AwaitingUpload",
  Authorised = "Authorised",
  InitiationPending = "InitiationPending",
}

export interface InstalmentPaymentItem {
  amount: Amount;
  dateTimeInstallment: string;
  status: "Pending" | "Paid";
}
export interface InstalmentDetailsResponse {
  rtpId: string;
  installmentPayments: InstalmentPaymentItem[];
  pendingToPay: Amount;
  totalPaid: Amount;
}

export enum SubscriptionFeatureTypes {
  None = "none",
  OnePlan = "One Plan",
  Flexible = "Flexible",
}

export type DateDefaultOptions = {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
};
export interface CardPaymentApp {
  id: number;
  status: CardPaymentApplicationStatus;
  companyName: string | null;
  processingMethod: CardPaymentProcessingMethod;
}

export enum CardPaymentProcessingMethod {
  ACHACH = "ACHACH",
  Acquiring = "Acquiring",
  All = "All",
}

export enum CardPaymentApplicationStatus {
  Approved = "Approved",
  Cancelled = "Cancelled",
  Declined = "Declined",
  New = "New",
  Pending = "Pending",
  Underwriting = "Underwriting",
}

export type ErrorCode = typeof ERROR_CODES_LIST[number];

export type CoPRequest = {
  sortCode: string;
  accountNumber: string;
  accountHolderName: string;
};

export enum CoPStatus {
  Success = "Success",
  NoLinkedAccount = "NoLinkedAccount",
  CloseMatch = "CloseMatch",
  InvalidOrNoMatch = "InvalidOrNoMatch",
  UnderReview = "ApplicationUnderReview",
}

export type OrgUser = {
  email: string;
  role: string;
  status: string;
  nextStatus?: string[];
};

export type PermissionFeatureState = {
  create: boolean;
  view: boolean;
  update: boolean;
  delete: boolean;
};

export interface PermissionState {
  user: PermissionFeatureState;
  rtp: PermissionFeatureState;
  organisation: PermissionFeatureState;
  invoice: PermissionFeatureState;
  contact: PermissionFeatureState;
  bank_ledger: PermissionFeatureState;
  clearing_ledger: PermissionFeatureState;
  group: PermissionFeatureState;
  bank_account: PermissionFeatureState;
  accounting_package: PermissionFeatureState;
  payment: PermissionFeatureState;
  card_payment: PermissionFeatureState;
}

export interface UserPermissionApiResponse {
  payload: {
    resource: string;
    action: string;
    status: string;
  }[];
}

export interface CardPaymentConsent {
  consentId: string;
  context: {
    custodianId: string;
    customerId: string;
    userId?: string;
    status?: ConsentStatus;
    created?: string;
    expires?: string;
    consentType: ConsentType;
    externalId?: string;
  };
}

export enum ConsentStatus {
  Authorized = "Authorized",
  ExpiredToken = "ExpiredToken",
  Invalid = "Invalid",
  Pending = "Pending",
  Rejected = "Rejected",
  Revoked = "Revoked",
}

export enum ConsentType {
  AccountInfo = "AccountInfo",
  CardProvider = "CardProvider",
  ErpData = "ErpData",
  PaymentAuthorization = "PaymentAuthorization",
}

export interface CardPaymentsSettings {
  providers: CardPaymentProviderSetting[];
  default?: {
    value: string;
  } | null;
}

export interface CardPaymentProviderSetting {
  id: string;
  enabled: boolean;
}

export enum ERPOrgType {
  OTHER_ORG = "OtherOrg",
  LIMITED_COMPANY = "LimitedCompany",
  SOLE_TRADER = "SoleTrader",
  LIMITED_LIABILITY_PARTNERSHIP = "LimitedLiabilityPartnership",
}

export enum PraeturaOrgType {
  LIMITED_LIABILITY_COMPANY = "limited_liability_company",
  LIMITED_LIABILITY_PARTNERSHIP = "limited_liability_partnership",
  SOLE_TRADER = "sole_trader",
  PARTNERSHIP = "partnership",
  OTHER = "other",
}

export type WcfCustomer = {
  status?: string;
  registeredCompanyName: string | null;
  tradingName?: string | null;
  companyType?: string;
  companyRegistrationNumber?: string | null;
  tradingFromDate: string;
  industry: string;
  sicCode?: string;
  website: string | null;
  registeredAddress?: {
    street?: string | null;
    cityOrTown?: string | null;
    countyOrState?: string | null;
    country?: string | null;
    postcode?: string | null;
  } | null;
  tradingAddress?: {
    street?: string | null;
    cityOrTown?: string | null;
    countyOrState?: string | null;
    country?: string | null;
    postcode?: string | null;
  } | null;
  vatInformation: {
    vatNumber?: string;
    isRegisteredForVat: boolean;
  };
  hasConsentedToCreditCheck: boolean;
  hasConsentedToPrivacyPolicy: boolean;
};

export interface Document {
  id: string;
  name: string;
}
