import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import parse from "html-react-parser";
import { NavLink as RouterLink, useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  Container,
  LinearProgress,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import {
  AccountingPackageSetupHeader,
  MessageCard,
  Page,
  ResultNotification,
} from "@APP/components";
import {
  fetchUserData,
  getPermissions,
  getUser,
  hideLoader,
  setDefaultAutomatedCollectionsState,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { SCREEN_PATHS } from "@APP/navigation";
import { API, AppLocalStorage, LocalStorageKey } from "@APP/services";
import { ErpId, TabsName } from "@APP/constants";
import { Custodian } from "@APP/types";
import { capitalize, formatErrorMessage, getErrorMessageByErrorCode } from "@APP/utils";
import { useAccessPermission, useAlert, useHandleErrorCodes } from "@APP/hooks";
import {
  CONSENT_REVOCATION_ERROR_CODES,
  ORG_COMPANY_VALIDATION_ERROR_CODES,
  ORG_VALIDATION_ERROR_CODES,
  REQUEST_LIMIT_REACHED_ERROR_CODE,
  SAGE_SUBSCRIPTION_NOT_FOUND,
} from "@APP/services/api";
import CONFIG from "@APP/config";
import { IMAGES } from "@APP/assets";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 120,
    marginBottom: theme.spacing(2),
  },
  paragraph: {
    marginBottom: theme.spacing(2),
    "& b": {
      color: theme.palette.text.primary,
    },
  },
}));

interface ERPConsentLocalData {
  erpId: ErpId;
  consentId: string;
}

type Props = {
  externalErpRedirectState: string;
};

const SetupAccountingPackage = ({ externalErpRedirectState }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const user = useSelector(getUser);
  const { t } = useTranslation();

  const [accountingPackages, setAccountingPackages] = useState<Custodian[] | null>(null);
  const [selectedPackage, setSelectedPackage] = useState<Custodian | null>(null);
  const [isConsentAuthorised, setIsConsentAuthorised] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [organisationError, setOrganisationError] = useState("");

  const { code, realmId, error } = queryString.parse(location.search);

  const permissions = useSelector(getPermissions);

  const { fetchAllPermissions } = useAccessPermission();

  useEffect(() => {
    fetchAllPermissions();
  }, []);

  useEffect(() => {
    /**
     * Fetches the external ERP data.
     * If a user has landed on the page with consent authorisation code, handles the authorisation.
     * Otherwise, cleans up all any locally stored data that can be left after previous consent initiation
     */
    const fetchAccountingPackagesData = async () => {
      await fetchAccountingPackages();
      if (!code) {
        AppLocalStorage.removeItem(LocalStorageKey.setupErpConsentData);
      }
      code && processConsentAuthorisation();
    };
    fetchAccountingPackagesData();
  }, [code]);

  const fetchAccountingPackages = async () => {
    setIsLoading(true);
    try {
      const availablePackages = await API.getCustodiansByType({
        custodianType: "AccountingPackage",
      });
      const filteredCustodians = availablePackages
        .filter((custodian) =>
          (!CONFIG.FEATURES.REGISTRATION.MOBILE_FLOW_ENABLED &&
            !CONFIG.FEATURES.GENERAL_FEATURES.includes("NATIVE_INVOICING")) ||
          (user?.erp === ErpId.INTERNAL &&
            user.org &&
            window.location.pathname.includes(SCREEN_PATHS.SETTINGS))
            ? custodian.id !== ErpId.INTERNAL
            : true,
        )
        .map((custodian) =>
          custodian.id === ErpId.INTERNAL
            ? {
                ...custodian,
                shortName: "No Accounting Package",
                fullName: t("Setup.SetupAccountingPackage.InternalSubTitle"),
                logo: IMAGES.ERP_ICON,
              }
            : custodian,
        );

      setAccountingPackages(filteredCustodians);
    } catch (error) {
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error), [
        { text: "Okay", onClick: () => history.replace(SCREEN_PATHS.SETTINGS) },
      ]);
    }
    setIsLoading(false);
  };

  /**
   * Initiates consent for the selected package,
   * serializes and stores the consent data locally for later use during consent authorisation.
   */
  const handleConsentInitiation = async () => {
    try {
      dispatch(showLoader());
      const erpData = await API.initiateERPConsent(selectedPackage!.id, externalErpRedirectState);
      const erpConsentLocalData: ERPConsentLocalData = {
        consentId: erpData.consentId,
        erpId: selectedPackage?.id as ErpId,
      };

      AppLocalStorage.setItem(
        LocalStorageKey.setupErpConsentData,
        JSON.stringify(erpConsentLocalData),
      );

      dispatch(hideLoader());
      window.location.href = erpData.redirectUrl!;
    } catch (error) {
      dispatch(hideLoader());
      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    }
  };

  const handleContinue = async () => {
    if (selectedPackage?.id === ErpId.INTERNAL) {
      return history.push(SCREEN_PATHS.COMPANY_TYPE_VIEW);
    }

    if (
      CONFIG.FEATURES.GENERAL_FEATURES.includes("NATIVE_INVOICING") &&
      user?.erp === ErpId.INTERNAL &&
      window.location.pathname.includes(SCREEN_PATHS.SETTINGS)
    ) {
      return alert.open(
        "",
        "You are about to link an accounting package from an alternative provider, who allow you to create customer and invoice records.<br />Customer and invoice records created in this package will not be reflected in your new accounting package.<br/>Please ensure you have a copy of all the data you require before linking your new software package.",
        [
          { text: "Cancel" },
          { text: "Okay", onClick: async () => await handleConsentInitiation() },
        ],
      );
    }

    await handleConsentInitiation();
  };

  const linkOrganisationData = async (erpConsentLocalData: ERPConsentLocalData) => {
    try {
      await API.linkERPOrganisation(erpConsentLocalData.erpId);
    } catch (error) {
      const errorData = error.response?.data;

      if (
        ORG_VALIDATION_ERROR_CODES.includes(errorData?.errorCode) ||
        ORG_COMPANY_VALIDATION_ERROR_CODES.includes(errorData?.errorCode) ||
        CONSENT_REVOCATION_ERROR_CODES.includes(errorData?.errorCode) ||
        errorData?.errorCode === REQUEST_LIMIT_REACHED_ERROR_CODE
      ) {
        setOrganisationError(getErrorMessageByErrorCode(errorData.errorCode));
      } else if (CONSENT_REVOCATION_ERROR_CODES.includes(errorData?.errorCode)) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          getErrorMessageByErrorCode(errorData.errorCode),
          [{ text: "Okay", onClick: () => history.push(SCREEN_PATHS.DASHBOARD) }],
        );
      } else {
        setOrganisationError(formatErrorMessage(error, JSON.stringify(error)));
      }
    }

    try {
      const { token } = await API.refreshToken();
      AppLocalStorage.setItem(LocalStorageKey.authToken, token);

      await dispatch(fetchUserData());
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;
      const isHandled = handleErrorCodes(errorCode);

      if (isHandled) {
        return setIsLoading(false);
      }

      alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), formatErrorMessage(error));
    }

    setIsLoading(false);
    setIsConsentAuthorised(true);
  };

  /**
   * Authorises ERP consent using the local data saved earlier at the stage of consent initiation.
   * Attempts to link organisation info from an accounting package to a user account.
   */
  const processConsentAuthorisation = async () => {
    // * Step 1: Authorise external ERP consent based on the local data saved after consent initiation.
    // Once finished, remove all local data related to consent initiation.
    const erpConsentLocalData: ERPConsentLocalData = JSON.parse(
      AppLocalStorage.getItem(LocalStorageKey.setupErpConsentData) || "",
    );

    if (!erpConsentLocalData) {
      return console.log("Consent initiation data is missing, please try again");
    }
    setIsLoading(true);
    try {
      await API.authorizeERPConsent(
        erpConsentLocalData.erpId,
        erpConsentLocalData.consentId,
        code as string,
        externalErpRedirectState,
        realmId ? (realmId as string) : undefined,
      );

      dispatch(setDefaultAutomatedCollectionsState());
    } catch (error) {
      // If, for some reason, сonsent authorization resulted in an error,
      // check whether the consent was actually authorized (handling a possible timeout on the backend side).
      // In the absence of a consent, show the error message. Otherwise continue with linking the organisation.
      try {
        const { currentErp } = await API.getCurrentERP();
        if (currentErp !== erpConsentLocalData.erpId) throw error;
        console.log("External ERP consent authorization was successful after an error.", error);
      } catch (error) {
        const errorData = error.response?.data;
        if (SAGE_SUBSCRIPTION_NOT_FOUND === errorData.errorCode) {
          alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            getErrorMessageByErrorCode(errorData.errorCode),
            [{ text: "Okay" }],
          );
        } else {
          alert.open(
            t("Settings.AccountingPackage.SetupAccountingPackage.ErrorErpConsentFailedTitle"),
            formatErrorMessage(error),
            [
              {
                text: "Okay",
                onClick: () =>
                  history.replace(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`),
              },
            ],
          );
        }
        setIsLoading(false);

        return;
      }
    }
    // * Step 2: Try to create the organisation based on the info received from external ERP.
    await linkOrganisationData(erpConsentLocalData);
  };

  const handleOkayButton = () => {
    setIsLoading(true);
    setIsConsentAuthorised(false);
    setOrganisationError("");

    history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`);
  };

  const renderContent = () => {
    if (isConsentAuthorised && !isLoading && !organisationError) {
      const linkForOkayButton =
        user?.bankAccounts?.length ||
        location.pathname === SCREEN_PATHS.SETTINGS ||
        CONFIG.FEATURES?.WORKING_CAPITAL_FINANCE_APPLICATION
          ? `${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`
          : SCREEN_PATHS.SETUP_BANK_ACCOUNTS;

      return (
        <ResultNotification
          type={organisationError ? "error" : "success"}
          footer={
            <Button
              color={organisationError ? "primary" : "secondary"}
              variant="contained"
              component={RouterLink}
              to={linkForOkayButton}
              id="ap-success-continue-btn"
              data-testid="success-continue-btn">
              Okay
            </Button>
          }>
          {organisationError
            ? parse(organisationError)
            : `Your ${capitalize(user?.erp)} accounting package was successfully linked.`}
        </ResultNotification>
      );
    } else if (isConsentAuthorised && !isLoading) {
      return (
        <Card elevation={4}>
          <Box textAlign="center" p={3}>
            <DataUsageIcon className={classes.icon} color="primary" />
            <Typography
              className={classes.paragraph}
              align="center"
              color="textPrimary"
              variant="h3">
              {t(
                "Settings.AccountingPackage.SetupAccountingPackage.FailedFetchOrgDataFromErp.Title",
              )}
            </Typography>
            <Typography className={classes.paragraph} align="center" color="textSecondary">
              {t(
                "Settings.AccountingPackage.SetupAccountingPackage.FailedFetchOrgDataFromErp.Text",
              )}
            </Typography>
            <Box mb={3}>
              <MessageCard type="error">
                <Typography color="inherit" variant="body2">
                  {parse(organisationError)}
                </Typography>
              </MessageCard>
            </Box>
            <Box mb={1}>
              <Button
                variant="contained"
                onClick={handleOkayButton}
                color="primary"
                id="setupAPOkButton">
                Okay
              </Button>
            </Box>
          </Box>
        </Card>
      );
    }

    return (
      <Card elevation={4}>
        <AccountingPackageSetupHeader
          headerText="Link Your Accounting Package"
          permissions={permissions}
          subHeaderText={
            permissions.organisation.create || !user?.org
              ? t("Setup.SetupAccountingPackage.SelectAccountingPackageText")
              : t("Setup.SetupAccountingPackage.SelectAccountingPackageTextWithoutPermission")
          }
          error={error}
        />
        {!error &&
          CONFIG.FEATURES.GENERAL_FEATURES.includes("MULTI_CHANNEL") &&
          !CONFIG.FEATURES.GENERAL_FEATURES.includes("NATIVE_INVOICING") && (
            <Box mt={3} mb={3}>
              <Typography
                align="center"
                color="textSecondary"
                variant="body2"
                data-testid="please-select-an-accounting-package-text">
                You can always use the {CONFIG.PRODUCT_NAME} mobile app without linking an
                accounting package.
              </Typography>
            </Box>
          )}
        {isLoading && (
          <Box mt={6}>
            <Typography
              className={classes.paragraph}
              align="center"
              color="textSecondary"
              data-testid="loading-text"
              variant="body2">
              Loading...
            </Typography>
            <LinearProgress />
          </Box>
        )}
        {!isLoading &&
          accountingPackages &&
          !isConsentAuthorised &&
          (permissions.organisation.create || !user?.org) && (
            <>
              <Box mb={3}>
                <Typography
                  align="center"
                  color="textPrimary"
                  variant="h4"
                  gutterBottom
                  data-testid="setup-your-accounting-package-title">
                  Choose Your Accounting Package:
                </Typography>
              </Box>
              {accountingPackages.map((accPackage) => (
                <ListItemButton
                  key={accPackage.id}
                  id={"accounting-package-" + accPackage.shortName.toLowerCase()}
                  selected={selectedPackage?.id === accPackage.id}
                  data-testid={"accounting-package-" + accPackage.shortName.toLowerCase()}
                  onClick={() => setSelectedPackage(accPackage)}
                  aria-label={`Select ${accPackage.shortName} accounting package`}
                  aria-pressed={selectedPackage?.id === accPackage.id}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center">
                    <ListItemAvatar>
                      <img
                        style={{ maxWidth: "40px" }}
                        src={accPackage.logo}
                        alt={accPackage.fullName}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={accPackage.shortName} secondary={accPackage.fullName} />
                  </Box>
                </ListItemButton>
              ))}
              <CardActions>
                <Box display="flex" flexDirection="column" width="100%">
                  <Button
                    disabled={!selectedPackage || isLoading}
                    variant="contained"
                    color="primary"
                    onClick={handleContinue}
                    id={"ap_selection_continue_button"}
                    fullWidth
                    data-testid="continue-button"
                    endIcon={selectedPackage && <KeyboardArrowRightIcon />}>
                    {`Continue ${selectedPackage ? `with ${selectedPackage.shortName}` : ""}`}
                  </Button>
                </Box>
              </CardActions>
            </>
          )}
      </Card>
    );
  };

  return (
    <Page title="Accounting Package">
      <Container maxWidth="md">{renderContent()}</Container>
    </Page>
  );
};

export default SetupAccountingPackage;
