import CONFIG from "@APP/config";
import { approvedStatuses } from "@APP/constants";
import { fetchBankAccounts, isDashboardVisible, isOcmVisible, useAppDispatch } from "@APP/redux";
import { API } from "@APP/services";

const useApplicationListing = () => {
  const dispatch = useAppDispatch();

  const getApplicationListing = async () => {
    if (CONFIG.FEATURES?.WORKING_CAPITAL_FINANCE_APPLICATION) {
      let bankAccounts = null;
      const userInfo = await API.getUserInfo();
      try {
        bankAccounts = await dispatch(fetchBankAccounts(userInfo));
      } catch (error) {
        // do nothing for now
      }
      try {
        const { data: wcfList } = await API.wcfListing();

        const approvedApplications = wcfList.data.filter((wcf: { praeturaStatus: string }) =>
          approvedStatuses.includes(wcf.praeturaStatus),
        );
        const isInvoice = approvedApplications.filter(
          (invoice: { invoiceFinanceRequest: null }) => invoice.invoiceFinanceRequest !== null,
        );

        if (approvedApplications.length > 0 && isInvoice.length === 0) {
          dispatch(isDashboardVisible(true));
          if (bankAccounts && bankAccounts?.length > 0) {
            dispatch(isOcmVisible(true));
          } else {
            dispatch(isOcmVisible(false));
          }
        } else {
          dispatch(isOcmVisible(false));
          dispatch(isDashboardVisible(false));
        }
      } catch (error) {
        dispatch(isOcmVisible(false));
        dispatch(isDashboardVisible(false));
      }
    }
  };
  return { getApplicationListing };
};

export default useApplicationListing;
