import { useCallback } from "react";
import { Card, Grid, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { parsePhoneNumber } from "libphonenumber-js";

import {
  FooterActionsButtons,
  InvoiceSummary,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
  Table,
} from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import {
  getInvoice,
  getUser,
  hideLoader,
  setCreatedReceivable,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { useAlert, useCreateInvoice, useHandleErrorCodes } from "@APP/hooks";
import { getFieldsValidationErrorParts } from "@APP/utils";
import { ERPPlanValue, ErrorCode, InvoiceType, LineItemType } from "@APP/types";
import { createInvoice, RTP_VALIDATION_ERROR_CODES } from "@APP/services/api";
import CONFIG from "@APP/config";
import { ErpId, Provider, VAT_RATE_NONE_VALUE } from "@APP/constants";

const ConfirmInvoice = () => {
  const history = useHistory();
  const locale = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const alert = useAlert();
  const { approveReceivable } = useCreateInvoice();

  const invoice = useSelector(getInvoice);
  const user = useSelector(getUser);

  const displayProductOrService =
    user?.erp !== ErpId.INTERNAL &&
    !(user?.erp === ErpId.SAGE && user.erpPlan === ERPPlanValue.START);

  const getInvoiceMobilePhone = () => {
    const isExistingCustomer = Boolean(invoice.customerContact.id);

    if (isExistingCustomer) {
      return invoice.customerContact.mobile || undefined;
    }

    return invoice.customerContact.mobile
      ? parsePhoneNumber(invoice.customerContact.mobile, CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE)
          .number
      : undefined;
  };

  const createReceivable = useCallback(async () => {
    const itemsData = {
      lineItems: invoice.lineItems.map((lineItem) => ({
        ...lineItem,
        amountTaxExclusive: {
          ...lineItem.amountTaxExclusive!,
          amount: lineItem.amountTaxExclusive!.amount.toString(),
        },
        categoryId: lineItem.productService?.details.externalId || null,
      })),
      dateTimeIssued: invoice.invoiceIssueDateTime,
      dueDateTime: invoice.invoiceDueDateTime,
    };

    try {
      const receivable = (
        await createInvoice(user?.erp!, InvoiceType.Receivables, {
          customer: {
            ...invoice.customerContact,
            mobile: getInvoiceMobilePhone(),
          },
          reference: invoice.paymentUniqueReference || undefined,
          ...itemsData,
          features: user?.erp === ErpId.INTERNAL ? ["Native"] : undefined,
        })
      ).data[0];

      return receivable;
    } catch (err) {
      throw err;
    }
  }, [alert, handleErrorCodes, invoice]);

  const handleCreateInvoice = async () => {
    dispatch(showLoader());
    try {
      const receivable = await createReceivable();
      if (receivable) {
        if (user?.erp !== ErpId.INTERNAL) {
          await approveReceivable(receivable);
        }

        dispatch(setCreatedReceivable(receivable));
        dispatch(hideLoader());
        history.push(SCREEN_PATHS.RECEIVABLE_SUCCESS);
      }
    } catch (err) {
      dispatch(hideLoader());
      const errorCode = err?.response?.data?.errorCode;
      const invalidFields = err?.response?.data?.invalidFields;
      if (errorCode) {
        const isHandled = handleErrorCodes(errorCode as ErrorCode);

        if (isHandled) return;
      }

      if (RTP_VALIDATION_ERROR_CODES.includes(errorCode) && invalidFields?.length) {
        const errorParts = getFieldsValidationErrorParts(invalidFields);

        return history.push({
          pathname: SCREEN_PATHS.RECEIVABLE_FAILURE,
          state: {
            errorMessage: t("Errors.InvoiceCreation.Alerts.InvalidFields.Message", {
              ERROR_FIELDS: errorParts,
            }),
          },
        });
      }

      if (errorCode === 6101) {
        return history.push({
          pathname: SCREEN_PATHS.RECEIVABLE_FAILURE,
          state: {
            errorCode: 6101,
            hideContinueButton: true,
          },
        });
      }

      if (errorCode === 9001) {
        return history.push({
          pathname: SCREEN_PATHS.RECEIVABLE_FAILURE,
          state: {
            errorCode: 9001,
            hideContinueButton: true,
          },
        });
      }

      history.push(SCREEN_PATHS.RECEIVABLE_FAILURE);
    }
  };

  const renderHeader = () => (
    <TableRow>
      {displayProductOrService && (
        <TableCell data-testid="product-service-field-label">Product/Service</TableCell>
      )}
      <TableCell data-testid="description-field-label">Description</TableCell>
      {user?.erp !== ErpId.QUICKBOOKS && user?.erp !== ErpId.INTERNAL && (
        <TableCell data-testid="account-field-label">Account</TableCell>
      )}
      <TableCell data-testid="quantity-field-label">Quantity</TableCell>
      <TableCell data-testid="unit-price-field-label">Unit Price</TableCell>
      <TableCell data-testid="vat-rate-field-label">{t("Invoice.VatRateLabel")}</TableCell>
      <TableCell data-testid="amount-field-label">{t("Invoice.AmountOfLineItemLabel")}</TableCell>
    </TableRow>
  );

  const renderRows = (item: LineItemType) => (
    <TableRow key={item.description}>
      {displayProductOrService && <TableCell>{item.productService?.name || "-"}</TableCell>}
      <TableCell style={{ wordBreak: "break-all" }}>{item.description}</TableCell>
      {user?.erp !== ErpId.QUICKBOOKS && user?.erp !== ErpId.INTERNAL && (
        <TableCell>
          {item.externalLedger?.code}-{item.externalLedger?.description}
        </TableCell>
      )}
      <TableCell>{item.quantity}</TableCell>
      <TableCell>{item.unitPrice}</TableCell>
      <TableCell>
        {item.pickerVatRate?.label && item.pickerVatRate?.requestValue !== VAT_RATE_NONE_VALUE
          ? item.pickerVatRate?.label
          : "-"}
      </TableCell>
      <TableCell>{item.amountTaxExclusive?.amount}</TableCell>
    </TableRow>
  );

  const handleNavigateToBack = () => history.push(SCREEN_PATHS.RECEIVABLES_LINE_ITEMS);

  return (
    <Page title="Confirm Invoice">
      <Grid item md={10} xs={9}>
        <ScreenHeader title="Confirm Invoice" id="confirmInvoiceTitle" />
        <ScreenHeaderSubtitle subtitle="Before creating the Invoice, please check the details below:" />
      </Grid>
      <InvoiceSummary
        customerContact={invoice.customerContact}
        invoiceIssueDateTime={invoice?.invoiceIssueDateTime}
        invoiceDueDateTime={invoice?.invoiceDueDateTime}
        totalAmountTaxExclusive={invoice.invoiceAmountNet.toString()}
        totalAmountTaxInclusive={
          Provider.isMaverick ? undefined : invoice.invoiceAmountGross.toString()
        }
        currency={invoice.invoiceCurrency}>
        <Card elevation={12}>
          <Table
            data={invoice.lineItems}
            renderHeader={renderHeader}
            renderRows={renderRows}
            showPagination={false}
          />
        </Card>
      </InvoiceSummary>
      <FooterActionsButtons
        backButtonText="Back"
        backButtonDataTestId="back-to-invoice-button"
        handleBackButton={handleNavigateToBack}
        handleContinue={handleCreateInvoice}
        continueButtonText={
          locale.pathname === SCREEN_PATHS.RECEIVABLES_CONFIRM ? "Continue" : undefined
        }
        continueButtonDataTestId="continue-button"
        continueButtonProps={{
          "aria-haspopup": "dialog",
          "aria-label": "show dialog to save invoice or proceed to payment request",
        }}
      />
    </Page>
  );
};

export default ConfirmInvoice;
