import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CssBaseline,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Link,
  List,
  ListItemAvatar,
  ListItemButton,
  MenuItem,
  Modal,
  SimplePaletteColorOptions,
  TextField,
  Theme,
  Toolbar,
  Typography,
  lighten,
  useTheme,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import makeStyles from "@mui/styles/makeStyles";
import PersonOutline from "@mui/icons-material/Person";

import { CommonTextField, Page } from "@APP/components";
import palette from "@APP/styles/theme/default/palette";
import { SCREEN_PATHS } from "@APP/navigation";
import WcfFooter from "@APP/components/layout/WCFLayout";
import {
  getUser,
  getWorkingCapitalFinanceDetails,
  hideLoader,
  setActiveStep,
  setApplicantFormData,
  setAssetFinanceFormData,
  setFormSubType,
  setFormType,
  setInvoiceFinanceFormData,
  setNextPath,
  setNextStepActive,
  setProductType,
  showLoader,
} from "@APP/redux";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";
import { DatePicker } from "@mui/x-date-pickers";
import { useAlert } from "@APP/hooks";
import CONFIG from "@APP/config";
import { assetTypes, countries, roles, urgencyLevels } from "@APP/views/wcf/utils/dropdown";
import { OptionCard } from "../utils";
import { removeUnderscoreAndCapitalize } from "@APP/views/wcf/utils/utils";
import { API } from "@APP/services";
import { GetDirectorsByRegistrationNumberType, WcfCustomer } from "@APP/types";
import { WcfForm } from "@APP/types/wcf";
import useHandleApplicantDetails from "@APP/views/wcf/applicant/useHandleApplicantDetails";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "32px",
    alignItems: "center",
    borderRadius: "5px",
    minHeight: "80vh",
    justifyContent: "flex-start",
  },
  list: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    overflowY: "auto",
    maxHeight: 300,
    borderRadius: theme.shape.borderRadius < 20 ? theme.shape.borderRadius : 20,
  },
  listItem: {
    borderRadius: theme.shape.borderRadius < 20 ? theme.shape.borderRadius : 20,
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      color: theme.palette.secondary.contrastText,
    },
    "&.Mui-selected:hover": {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      color: theme.palette.secondary.contrastText,
    },
  },
  avatarIcon: {
    minWidth: 30,
    [theme.breakpoints.up("md")]: {
      minWidth: 40,
    },
  },
  modalHeader: {
    display: "flex",
    marginBottom: "4%",
    position: "sticky",
    top: 0,
    zIndex: 9999,
  },
  topBar: {
    position: "absolute",
    width: "calc(100% + 64px)",
    left: "-32px",
    right: "auto",
    top: "-32px",
  },
}));

const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "90vh",
  overflow: "Scroll",
  bgcolor: "background.paper",
  p: 4,
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface Applicant {
  id: number;
  firstName: string;
  lastName: string;
  mainApplicant: string;
}

const initialState = [
  {
    label: "Yes",
    selected: true,
  },
  {
    label: "No",
    selected: false,
  },
];

function ApplicantDetails() {
  const {
    wcfFormData: { applicantDetailsForm },
  } = useSelector(getWorkingCapitalFinanceDetails);

  const user = useSelector(getUser);
  const [directorList, setDirectorList] = useState<GetDirectorsByRegistrationNumberType[] | null>(
    null,
  );
  const [selectedDirector, setSelectedDirector] =
    useState<GetDirectorsByRegistrationNumberType | null>(null);
  const [customer, setCustomer] = useState<WcfCustomer | null>(null);

  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const alert = useAlert();
  const history = useHistory();
  const handleApplicationDetails = useHandleApplicantDetails();

  const [edit, setEdit] = useState(false);
  const [mainApp, setMainApp] = useState(initialState);
  const [mainClicked, setMainClicked] = useState(false);
  const [editClicked, setEditClicked] = useState(false);
  const [addClicked, setAddClicked] = useState(false);
  const [open, setOpen] = useState(false);

  const { applicationId }: { applicationId: string } = useParams();

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    resetForm();
    setSelectedDirector(null);
    if (!editClicked && !addClicked) {
      const applicants = localStorage.getItem("applicants");
      dispatch(setApplicantFormData(JSON.parse(applicants as string)));
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === " " && (event.target as HTMLInputElement).value.trim() === "") {
      event.preventDefault();
    }
  };

  const isLimitedCompany =
    customer?.companyType === "limited_liability_company" ||
    customer?.companyType === "limited_liability_partnership";

  const validate = (values: {
    id: number;
    middleName: string | null;
    firstName: string;
    lastName: string;
    dateOfBirth: Date | string | null;
    address: string;
    city: string;
    postcode: string;
    country: string;
    role: string;
    mainApplicant: string;
    guarantee: string;
    privacyPolicy: boolean;
    creditCheck: boolean;
    email: string;
    phoneNumber: string;
  }) => {
    let errors = {};
    if (!values.firstName) {
      errors = {
        ...errors,
        firstName: "Please enter the first name.",
      };
    } else if (values.firstName.length > 50) {
      errors = { ...errors, firstName: "Maximum 50 characters" };
    }
    if (values?.middleName && values?.middleName?.length > 50) {
      errors = { ...errors, middleName: "Maximum 50 characters" };
    }
    if (!values.lastName) {
      errors = {
        ...errors,
        lastName: "Please enter the first name.",
      };
    } else if (values.lastName.length > 50) {
      errors = { ...errors, lastName: "Maximum 50 characters" };
    }
    if (!values.dateOfBirth || values.dateOfBirth.toString() === "Invalid Date") {
      errors = {
        ...errors,
        dateOfBirth: "Date of birth is required.",
      };
    } else if (new Date(values.dateOfBirth as string) > new Date()) {
      errors = {
        ...errors,
        dateOfBirth: "Please enter a valid date",
      };
    }
    if (!values.address) {
      errors = {
        ...errors,
        address: "Please enter valid address",
      };
    }
    if (!values.city) {
      errors = {
        ...errors,
        city: "Please enter valid city",
      };
    }
    const postcodeRegex =
      /^((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Za-z][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Za-z][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;
    if (!values.postcode) {
      errors = {
        ...errors,
        postcode: "Postcode is required.",
      };
    } else if (!postcodeRegex.test(values.postcode)) {
      errors = {
        ...errors,
        postcode: "Please enter correct postcode",
      };
    }
    if (!values.country) {
      errors = {
        ...errors,
        country: "Please enter valid country",
      };
    }
    if (!values.role) {
      errors = {
        ...errors,
        role: "Please enter valid role",
      };
    }
    if (!values.mainApplicant) {
      errors = {
        ...errors,
        city: "Please enter valid city",
      };
    }
    if (!values.guarantee) {
      errors = {
        ...errors,
        guarantee: "Please enter valid guarantee",
      };
    }
    if (!values.privacyPolicy) {
      errors = {
        ...errors,
        privacyPolicy: "Please accept the privacy policy.",
      };
    }
    if (!values.creditCheck) {
      errors = {
        ...errors,
        creditCheck: "Please give consent to credit check.",
      };
    }
    const emailRegex =
      /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/;
    if (!values.email) {
      errors = {
        ...errors,
        email: "Please enter email",
      };
    } else if (!emailRegex.test(values.email)) {
      errors = {
        ...errors,
        email: "Please enter a valid email",
      };
    }
    const phoneRegex = /^(0|\+44)\d{10}$/;
    if (!values.phoneNumber) {
      errors = {
        ...errors,
        phoneNumber: "Please enter a phone number",
      };
    } else if (!phoneRegex.test(values.phoneNumber)) {
      errors = {
        ...errors,
        phoneNumber: "Please enter a valid phone number",
      };
    }

    return errors;
  };

  const {
    handleChange,
    handleSubmit,
    values,
    resetForm,
    setFieldValue,
    touched,
    errors,
    setFieldTouched,
    handleBlur,
  } = useFormik({
    initialValues: {
      id: 0,
      firstName: "",
      middleName: "",
      lastName: "",
      dateOfBirth: "",
      address: "",
      city: "",
      postcode: "",
      country: countries[0].value,
      role: applicantDetailsForm?.[0]?.role ?? roles[0].value,
      mainApplicant: "Yes",
      guarantee: "Yes",
      privacyPolicy: false,
      creditCheck: false,
      email: user?.username ?? "",
      phoneNumber: user?.phone ?? "0",
    },
    validate,
    onSubmit: () => {
      setEdit(false);
      setMainClicked(false);
      const updatedApplicants =
        values.mainApplicant === "Yes"
          ? applicantDetailsForm?.map((applicant) => {
              return {
                ...applicant,
                mainApplicant: "No",
                middleName: applicant.middleName ?? null,
              };
            })
          : applicantDetailsForm?.map((applicant) => {
              return {
                ...applicant,
                middleName: applicant.middleName ?? null,
              };
            });

      dispatch(
        setApplicantFormData([
          ...(updatedApplicants ?? []),
          { ...values, id: applicantDetailsForm?.length ?? 0 },
        ]),
      );
      handleApplicationDetails([
        ...(updatedApplicants ?? []),
        { ...values, id: applicantDetailsForm?.length ?? 0 },
      ]);

      resetForm();
      setMainApp(initialState);
      handleClose();
      setEditClicked(false);
      setAddClicked(false);
    },
  });

  const editApplicant = (id: number) => {
    setEdit(true);
    const applicant = applicantDetailsForm?.find((applicant) => applicant.id === id);
    let applicantDOB;
    if (applicant?.dateOfBirth && applicant?.dateOfBirth?.toString().includes("Time")) {
      applicantDOB = applicant?.dateOfBirth;
    } else {
      applicantDOB = new Date(applicant?.dateOfBirth as string);
    }

    const updatedApplicants = applicantDetailsForm
      ?.filter((applicant) => applicant.id !== id)
      ?.map((app, idx) => {
        return { ...app, id: idx };
      });

    const mApp = [
      {
        label: "Yes",
        selected: !!(applicantDetailsForm?.[id]?.mainApplicant === "Yes"),
      },
      {
        label: "No",
        selected: !!(applicantDetailsForm?.[id]?.mainApplicant === "No"),
      },
    ];
    setMainApp(mApp);

    setFieldValue("id", id);
    setFieldValue("firstName", applicant?.firstName ?? "");
    setFieldValue("middleName", applicant?.middleName ?? null);
    setFieldValue("lastName", applicant?.lastName ?? "");
    setFieldValue("dateOfBirth", applicantDOB ?? null);
    setFieldValue("address", applicant?.address ?? "");
    setFieldValue("city", applicant?.city ?? "");
    setFieldValue("postcode", applicant?.postcode ?? "");
    setFieldValue("country", applicant?.country ?? "");
    setFieldValue("role", applicant?.role ?? "");
    setFieldValue("mainApplicant", applicant?.mainApplicant);
    setFieldValue("guarantee", applicant?.guarantee ?? "");
    setFieldValue("privacyPolicy", applicant?.privacyPolicy ?? false);
    setFieldValue("creditCheck", applicant?.creditCheck ?? false);
    setFieldValue("email", applicant?.email ?? "");
    setFieldValue("phoneNumber", applicant?.phoneNumber ?? 0);

    dispatch(setApplicantFormData(updatedApplicants));

    let director = directorList?.find(
      (dir) =>
        dir.validated_name.first_name === applicant?.firstName &&
        dir.validated_name.last_name === applicant?.lastName,
    );
    setSelectedDirector(director ?? null);
  };

  const removeApplicant = async (id: number) => {
    const updatedApplicants = applicantDetailsForm?.filter((applicant) => {
      return applicant.id !== id;
    });
    dispatch(setApplicantFormData(updatedApplicants));
    handleApplicationDetails(updatedApplicants);
  };

  const handleMainApplicantChange = (label: string) => {
    setMainClicked(true);
    const selecteMainApp = mainApp.map((insure) =>
      insure.label === label ? { ...insure, selected: true } : { ...insure, selected: false },
    );
    setMainApp(selecteMainApp);

    if (label === "No" && !mainClicked) {
      setFieldValue("email", "");
      setFieldValue("phoneNumber", "");
    }
    setFieldValue("mainApplicant", label);
  };

  const handleApplicantEdit = (applicantId: number) => {
    handleOpen();
    edit
      ? alert.open("Error", "Please save the current applicant details")
      : editApplicant(applicantId);
  };

  const handleAddApplicants = () => {
    handleOpen();
  };

  const handleDirectorChange = (director: GetDirectorsByRegistrationNumberType) => {
    if (director) {
      setFieldValue(
        "address",
        `${director?.address?.address_line_1 ?? ""} ${director?.address?.address_line_2 ?? ""}`,
      );
      setFieldValue("city", director?.address?.locality);
      setFieldValue("postcode", director?.address?.postal_code);
      setFieldValue("firstName", director?.validated_name?.first_name);
      setFieldValue("middleName", director?.validated_name?.middle_name ?? "");
      setFieldValue("lastName", director?.validated_name?.last_name);
      if (!director?.date_of_birth) {
        setFieldValue("dateOfBirth", null);
      } else {
        setFieldValue(
          "dateOfBirth",
          new Date(
            director?.date_of_birth?.year ? Number(director?.date_of_birth?.year) : 1970,
            director?.date_of_birth?.month ? Number(director?.date_of_birth?.month) - 1 : 0,
            director?.date_of_birth?.date ? Number(director?.date_of_birth?.date) : 1,
          ),
        );
      }
    }
    setSelectedDirector(director);
  };

  useEffect(() => {
    if (!edit && applicantDetailsForm) {
      localStorage.setItem("applicants", JSON.stringify(applicantDetailsForm));
    }
    if (!applicantDetailsForm) {
      localStorage.setItem("applicants", JSON.stringify([]));
    }
  }, [edit, applicantDetailsForm]);

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      await retrieveApplication();
      await fetchWcfCustomer();
      dispatch(setNextPath(`${SCREEN_PATHS.WCF_FORM_SUMMARY}/${applicationId}`));
      dispatch(setActiveStep(3));
      dispatch(setNextStepActive(true));
      dispatch(hideLoader());
    })();
  }, []);

  const retrieveApplication = async () => {
    const res = await API.getApplicationDetailsById(applicationId);
    const retrievedApplicants =
      res.data.applicants &&
      res?.data?.applicants.map((applicant, idx) => {
        return {
          id: idx,
          firstName: applicant.firstName,
          middleName: applicant.middleName,
          lastName: applicant.lastName,
          dateOfBirth: applicant.dateOfBirth,
          address: applicant.address.street,
          city: applicant.address.cityOrTown,
          postcode: applicant.address.postcode,
          country: applicant.address.country,
          role: applicant.companyRole,
          mainApplicant: applicant.isMainApplicant ? "Yes" : "No",
          guarantee: applicant.guarantees.can_provide_personal_guarantee ? "Yes" : "No",
          privacyPolicy: applicant.hasConsentedToPrivacyPolicy,
          creditCheck: applicant.hasConsentedToCreditCheck,
          email: applicant.contactDetails.email,
          phoneNumber: applicant.contactDetails.mobilePhoneNumber,
        };
      });

    if (
      res?.data?.applicants?.[0]?.firstName !== null &&
      applicantDetailsForm?.[0]?.firstName !== ""
    ) {
      dispatch(setApplicantFormData(retrievedApplicants as WcfForm["applicantDetailsForm"]));
    }

    if (res.data.assetFinanceRequest) {
      dispatch(
        setAssetFinanceFormData({
          units: res.data.assetFinanceRequest?.assets[0].numberOfUnits ?? 1,
          pricePerUnit: res.data.assetFinanceRequest?.assets[0].costPerUnit ?? 1,
          duration: res.data.assetFinanceRequest?.duration.amount?.toString() ?? "60",
          isRefinance: res.data.assetFinanceRequest?.isRefinance ?? false,
          deposit: res.data.assetFinanceRequest?.depositOrInitialRentalAmount ?? 1,
          purpose: res.data.assetFinanceRequest?.assets[0].description ?? "",
          urgency: res.data.urgency ?? urgencyLevels[0].value,
          assetType: res.data.assetFinanceRequest?.assets[0].category ?? assetTypes[0].value,
          model: res.data.assetFinanceRequest?.assets[0].model ?? "",
          manufacturer: res.data.assetFinanceRequest?.assets[0].manufacturer ?? "",
          yearOfManufactured: res.data.assetFinanceRequest?.assets[0].manufactureYear ?? 1250,
          isNew: res.data.assetFinanceRequest?.assets[0].condition ?? "new",
          isInsured: res.data.assetFinanceRequest?.assets[0].isInsured ?? false,
          assetPic: [],
          invoicePic: [],
        }),
      );
    }

    if (res.data.invoiceFinanceRequest) {
      dispatch(
        setInvoiceFinanceFormData({
          annualTurnover: res.data.invoiceFinanceRequest?.annualTurnover ?? 1,
          purpose: res.data.detailedFundingPurpose ?? "",
          urgency: res.data.urgency ?? "",
        }),
      );
    }

    if (res && res?.data && res?.data?.praeturaStatus)
      history.push(`${SCREEN_PATHS.WCF_FINANCE_LISTING}`);
    const isInvoice = !res.data.assetFinanceRequest;
    dispatch(setProductType(res?.data?.assetFinanceRequest?.productType as string));
    dispatch(setFormType(isInvoice ? "Invoice Finance" : "Asset Finance"));
    if (!isInvoice)
      dispatch(
        setFormSubType(
          removeUnderscoreAndCapitalize(res.data.assetFinanceRequest?.productType ?? ""),
        ),
      );
  };

  const getDirectorList = async (companyNumber: string) => {
    try {
      const { data } = await API.getDirectorsByRegistrationNumber(companyNumber);
      const activeDirectors = data.items.filter(
        (director: GetDirectorsByRegistrationNumberType) => director.resigned_on === null,
      );
      setDirectorList(activeDirectors);
    } catch (error) {
      setDirectorList([]);
    }
  };

  const fetchWcfCustomer = async () => {
    try {
      const customer = await API.getWcfCustomer();
      if (
        customer?.companyType === "limited_liability_company" ||
        customer?.companyType === "limited_liability_partnership"
      ) {
        getDirectorList(customer.companyRegistrationNumber as string);
      }
      setCustomer(customer);
    } catch (error) {
      setCustomer(null);
    }
  };

  return (
    <Page display="flex" flexDirection="column" p={0}>
      <Box p={3}>
        <Card elevation={1} className={classes.cardContainer}>
          <Grid style={{ width: "100%" }}>
            <WcfStepper />
            <Grid container mt={1}>
              <Typography
                variant="h4"
                component="p"
                fontWeight={600}
                color="primary"
                paddingBottom={2}>
                Complete the applicant details
              </Typography>
              <Grid container spacing={1} pb={2}>
                <Grid item xs={12}>
                  <Typography variant="body2" color="textSecondary">
                    You are almost there, you only need to fill the personal details to complete
                    your application. <br /> You can add more than applicant if required.
                  </Typography>
                </Grid>
              </Grid>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <>
                  <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                    <Box sx={modalStyle}>
                      <Box className={classes.modalHeader}>
                        <CssBaseline />
                        <AppBar className={classes.topBar} component="nav">
                          <Toolbar>
                            <IconButton
                              color="inherit"
                              aria-label="open drawer"
                              edge="start"
                              sx={{ mr: 2, display: { sm: "none" } }}></IconButton>
                            <Typography
                              variant="h3"
                              component="div"
                              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
                              {edit ? "Edit Applicant" : "Add New Applicant"}
                            </Typography>
                            <IconButton
                              sx={{ zIndex: "9999", right: 0, color: "#fff" }}
                              onClick={handleClose}>
                              <CancelIcon />
                            </IconButton>
                          </Toolbar>
                        </AppBar>
                      </Box>
                      <Grid item xs={12} md={12}>
                        {isLimitedCompany && (
                          <Typography variant="body2" color="textSecondary">
                            You can select from the below directors to populate some of the
                            applicant data. You can also manually complete the applicant details
                            below. You can add more than one applicant if required.
                          </Typography>
                        )}
                        <Grid container spacing={1} py={2}>
                          {isLimitedCompany && (
                            <Grid container spacing={1} pb={2}>
                              <Grid item xs={12}>
                                <List
                                  className={classes.list}
                                  component={Card}
                                  elevation={1}
                                  id="wcfApplicantFormDirectorList">
                                  {directorList?.map((director) => (
                                    <ListItemButton
                                      className={classes.listItem}
                                      selected={director.name === selectedDirector?.name}
                                      key={director.name}
                                      onClick={() => handleDirectorChange(director)}
                                      aria-pressed={director.name === selectedDirector?.name}
                                      id="wcfApplicantFormDirectorListButton">
                                      <ListItemAvatar
                                        className={classes.avatarIcon}
                                        id="wcfApplicantFormDirectorListAvatar">
                                        <PersonOutline
                                          fontSize="medium"
                                          color="primary"
                                          id="wcfApplicantFormDirectorListIcon"
                                        />
                                      </ListItemAvatar>
                                      <Typography
                                        variant="body2"
                                        id="wcfApplicantFormDirectorListTitle">
                                        {director.name}
                                      </Typography>
                                    </ListItemButton>
                                  ))}
                                </List>
                              </Grid>
                            </Grid>
                          )}
                          <Grid item xs={12} md={6}>
                            <CommonTextField
                              label="First Name: *"
                              name="firstName"
                              id="wcfApplicantFormFirstNameInput"
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.value !== selectedDirector?.validated_name?.first_name)
                                  setSelectedDirector(null);
                              }}
                              value={values.firstName}
                              onBlur={handleBlur}
                              onKeyDown={handleKeyPress}
                              error={Boolean(touched.firstName && errors.firstName)}
                              helperText={touched.firstName && errors.firstName}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CommonTextField
                              label="Middle Name:"
                              name="middleName"
                              id="wcfApplicantFormMiddleNameInput"
                              onBlur={handleBlur}
                              onKeyDown={handleKeyPress}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              value={values.middleName}
                              error={Boolean(touched.middleName && errors.middleName)}
                              helperText={touched.middleName && errors.middleName}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <CommonTextField
                              label="Last Name: *"
                              name="lastName"
                              id="wcfApplicantFormLastNameInput"
                              onBlur={handleBlur}
                              onKeyDown={handleKeyPress}
                              onChange={(e) => {
                                handleChange(e);
                                if (e.target.value !== selectedDirector?.validated_name?.last_name)
                                  setSelectedDirector(null);
                              }}
                              value={values.lastName}
                              error={Boolean(touched.lastName && errors.lastName)}
                              helperText={touched.lastName && errors.lastName}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <DatePicker
                              sx={{ width: "100%" }}
                              slotProps={{
                                textField: {
                                  error: Boolean(touched.dateOfBirth && errors.dateOfBirth),
                                  helperText:
                                    touched.dateOfBirth &&
                                    (errors.dateOfBirth as string | undefined),
                                  name: "dateOfBirth",
                                  id: "wcfApplicantFormDOBInput",
                                  onClick: () =>
                                    !touched.dateOfBirth &&
                                    setFieldTouched("dateOfBirth", true, false),
                                },
                              }}
                              onChange={(value) => {
                                !touched.dateOfBirth && setFieldTouched("dateOfBirth", true, false);
                                setFieldValue(
                                  "dateOfBirth",
                                  isNaN(Number(value)) ? null : value,
                                  true,
                                );
                              }}
                              value={values.dateOfBirth}
                              format="dd-MM-yyyy"
                              label="Date Of Birth: *"
                              aria-label="applicant date picker"
                              disableFuture
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} py={2}>
                          <Grid item xs={12} pb={1}>
                            <CommonTextField
                              label="Address: *"
                              name="address"
                              id="wcfApplicantFormAddressInput"
                              value={values.address}
                              onBlur={handleBlur}
                              onKeyDown={handleKeyPress}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              error={Boolean(touched.address && errors.address)}
                              helperText={touched.address && errors.address}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CommonTextField
                              label="City: *"
                              name="city"
                              id="wcfApplicantFormCityInput"
                              value={values.city}
                              onBlur={handleBlur}
                              onKeyDown={handleKeyPress}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              error={Boolean(touched.city && errors.city)}
                              helperText={touched.city && errors.city}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <CommonTextField
                              label="Postcode: *"
                              name="postcode"
                              id="wcfApplicantFormPostcodeInput"
                              value={values.postcode}
                              onBlur={handleBlur}
                              onKeyDown={handleKeyPress}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              error={Boolean(touched.postcode && errors.postcode)}
                              helperText={touched.postcode && errors.postcode}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              sx={{ width: "100%" }}
                            />
                          </Grid>
                          <Grid item xs={12} mt={1}>
                            <TextField
                              name="country"
                              id="wcfApplicantFormCountryInput"
                              select
                              label="Country: *"
                              value={values.country}
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              error={Boolean(touched.country && errors.country)}
                              helperText={touched.country && errors.country}
                              sx={{ width: "100%" }}>
                              {countries.map(({ label, value }) => (
                                <MenuItem
                                  key={value}
                                  value={value}
                                  style={getStyles(value, [], theme)}>
                                  {label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="role"
                            id="wcfApplicantFormRoleDropdown"
                            select
                            label="What is your role?: *"
                            value={values.role}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            error={Boolean(touched.role && errors.role)}
                            helperText={touched?.role && errors?.role}
                            sx={{ width: "100%" }}>
                            {roles.map(({ label, value }) => (
                              <MenuItem
                                key={value}
                                value={value}
                                id={"wcfApplicantFormRoleSelect_" + value}
                                style={getStyles(value, [], theme)}>
                                {label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} my={2}>
                          <Typography variant="body1">Are you the main applicant?: *</Typography>
                          <Grid display="flex" gap={1}>
                            {mainApp.map((value) => (
                              <OptionCard
                                key={value.label}
                                label={value.label}
                                selected={value.selected}
                                props={{
                                  onClick: () => {
                                    handleMainApplicantChange(value.label);
                                  },
                                }}
                              />
                            ))}
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" pb={2}>
                            A personal guarantee isn't needed to process your application now, but
                            could be needed later.
                          </Typography>
                        </Grid>
                        <Grid container display="flex" alignItems="center">
                          <Grid item xs={12}>
                            <FormControl
                              error={Boolean(touched.privacyPolicy && errors.privacyPolicy)}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="privacyPolicy"
                                    checked={values.privacyPolicy}
                                    id="wcfApplicantFormPrivacyPolicyCheckbox"
                                    onChange={(e) => {
                                      setFieldValue("privacyPolicy", e.target.checked);
                                    }}
                                  />
                                }
                                label={
                                  <Typography>
                                    By ticking this box I agree to{" "}
                                    <Link
                                      href={CONFIG.URLS.PRAETURA_PRIVACY_POLICY}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      id="wcfApplicantFormPrivacyPolicyCheckboxLink">
                                      Praetura's Privacy Policy
                                    </Link>
                                  </Typography>
                                }
                              />
                              {Boolean(touched.privacyPolicy && errors.privacyPolicy) && (
                                <Typography variant="caption" color="error">
                                  {touched.privacyPolicy && errors.privacyPolicy}
                                </Typography>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container display="flex" justifyContent="space-between" spacing={1}>
                          <Grid item xs={12}>
                            <FormControl error={Boolean(touched.creditCheck && errors.creditCheck)}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name="creditCheck"
                                    id="wcfApplicantFormCreditCheckCheckbox"
                                    checked={values.creditCheck}
                                    onChange={(e) => {
                                      setFieldValue("creditCheck", e.target.checked);
                                    }}
                                  />
                                }
                                label={
                                  <Typography>
                                    By ticking this box I consent to Praetura Finance performing a
                                    Credit check
                                  </Typography>
                                }
                              />
                              {Boolean(touched.creditCheck && errors.creditCheck) && (
                                <Typography variant="caption" color="error">
                                  {touched.creditCheck && errors.creditCheck}
                                </Typography>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6" pb={2}>
                            Please provide contact information for this application
                          </Typography>
                          <CommonTextField
                            label="Email Address:*"
                            name="email"
                            value={values.email}
                            error={Boolean(touched.email && errors.email)}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyPress}
                            helperText={touched.email && errors.email}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{ width: "100%" }}
                          />
                          <CommonTextField
                            label="Phone Number:*"
                            name="phoneNumber"
                            value={values.phoneNumber}
                            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                            onBlur={handleBlur}
                            onKeyDown={handleKeyPress}
                            helperText={touched.phoneNumber && errors.phoneNumber}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            sx={{ width: "100%", marginTop: 2 }}
                          />
                        </Grid>
                      </Grid>
                      {!edit ? (
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={() => setAddClicked(true)}
                          sx={{
                            marginTop: "2%",
                            borderRadius: "5px",
                            backgroundColor: (palette.secondary as SimplePaletteColorOptions)?.main,
                          }}>
                          Add Applicant
                        </Button>
                      ) : (
                        <Button
                          type="submit"
                          variant="contained"
                          onClick={() => setEditClicked(true)}
                          sx={{
                            marginTop: "2%",
                            borderRadius: "5px",
                            backgroundColor: (palette.secondary as SimplePaletteColorOptions)?.main,
                          }}>
                          Edit Applicant
                        </Button>
                      )}
                    </Box>
                  </form>
                </>
              </Modal>
              <Grid></Grid>
              <Grid item xs={12} md={6}>
                {applicantDetailsForm && applicantDetailsForm?.length > 0 && (
                  <Typography
                    sx={{ paddingLeft: "16px" }}
                    variant="h4"
                    component="p"
                    fontWeight={600}
                    color="primary"
                    paddingBottom={2}>
                    List of Applicants
                  </Typography>
                )}
                {applicantDetailsForm &&
                  applicantDetailsForm?.sort().map((applicants: Applicant, index) => (
                    <Card key={index} sx={{ margin: "16px", borderRadius: "5px" }}>
                      <Grid container spacing={1} p="16px 16px 8px 16px">
                        <Grid item xs={12}>
                          <Grid item display="flex" alignItems="center" gap={1} xs={12}>
                            <Typography variant="h6">Name:</Typography>
                            <Typography variant="body2" color="textSecondary">
                              {`${applicants.firstName} ${applicants.lastName}`}
                            </Typography>
                            {applicants.mainApplicant === "Yes" && <Chip label="Primary" />}
                          </Grid>
                          <Grid item display="flex" alignItems="center" margin={2} gap={2} xs={12}>
                            <Grid
                              item
                              sx={{ marginLeft: "auto", marginRight: "-10px" }}
                              spacing={1}>
                              <Button
                                size="small"
                                variant="outlined"
                                onClick={() => handleApplicantEdit(applicants.id)}
                                style={{ marginRight: "4px" }}>
                                Edit
                              </Button>
                              <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={() => removeApplicant(applicants.id)}>
                                Delete
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  ))}
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                margin: "16px",
              }}>
              <Button
                variant="contained"
                sx={{
                  marginTop: "2%",
                  borderRadius: "5px",
                  backgroundColor: (palette.secondary as SimplePaletteColorOptions)?.main,
                }}
                onClick={handleAddApplicants}>
                Add Applicant(s)
              </Button>
            </Grid>
          </Grid>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter
        displaySaveAndExitButton={true}
        displayPrevButton={true}
        disablePrevious={edit}
        displayNextButton={true}
      />
    </Page>
  );
}

export default ApplicantDetails;
