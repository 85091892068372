import { createReducer } from "deox";
import { endOfDay } from "date-fns";

import {
  setDefaultInvoiceState,
  setInvoiceAmountGross,
  setInvoiceCurrency,
  setInvoiceDueDateTime,
  setInvoiceIssueDateTime,
  setInvoiceLineItems,
  setInvoiceTotalAmount,
  setPayerEmail,
  setPayerId,
  setPayerName,
  setPayerPhone,
  setCreatedReceivable,
  setAddressInformation,
  setInvoiceReference,
} from "@APP/redux/actions";
import { BankAccount, LineItemType, Receivable, RTPDeliveryChannel, Contact } from "@APP/types";
import CONFIG from "@APP/config";

export interface InvoiceState {
  customerContact: Contact;
  paymentDescription: string;
  paymentUniqueReference: string;
  paymentDueDate: Date;
  paymentAmount: string;
  paymentAccounts: BankAccount[];
  paymentAccount: BankAccount | null;
  // invoiceNumber: string;
  lineItems: LineItemType[];
  invoiceAmountGross: number;
  invoiceAmountNet: number;
  invoiceIssueDateTime: Date;
  invoiceDueDateTime: Date;
  invoiceCurrency: string;
  deliveryMethod: RTPDeliveryChannel;
  rtpQRCodeUrl: string | null;
  externalReceivable: Receivable | null;
}

export const defaultState: InvoiceState = {
  customerContact: {
    name: "",
  },
  paymentDescription: "",
  paymentUniqueReference: "",
  paymentAmount: "",
  paymentDueDate: endOfDay(new Date()),
  paymentAccounts: [],
  paymentAccount: null,
  // invoiceNumber: "",
  invoiceCurrency: CONFIG.INPUTS.SUPPORTED_CURRENCIES[0],
  lineItems: [
    {
      description: "",
      externalLedger: null,
      quantity: "1",
      unitPrice: "",
      vatRate: "",
      amountTaxExclusive: {
        amount: 0,
        currency: CONFIG.INPUTS.SUPPORTED_CURRENCIES[0],
      },
    },
  ],
  invoiceAmountGross: 0,
  invoiceAmountNet: 0,
  invoiceIssueDateTime: new Date(),
  invoiceDueDateTime: endOfDay(new Date()),
  deliveryMethod: RTPDeliveryChannel.Email,
  rtpQRCodeUrl: null,
  externalReceivable: null,
};

const invoiceReducer = createReducer(defaultState, (handleAction) => [
  handleAction(setPayerName, (state, { payload }) => ({
    ...state,
    customerContact: {
      ...state.customerContact,
      name: payload,
    },
  })),
  handleAction(setPayerEmail, (state, { payload }) => ({
    ...state,
    customerContact: {
      ...state.customerContact,
      email: payload,
    },
  })),
  handleAction(setPayerPhone, (state, { payload }) => ({
    ...state,
    customerContact: {
      ...state.customerContact,
      mobile: payload,
    },
  })),
  handleAction(setInvoiceTotalAmount, (state, { payload }) => ({
    ...state,
    invoiceAmountNet: isNaN(payload) ? 0 : payload,
  })),
  handleAction(setInvoiceAmountGross, (state, { payload }) => ({
    ...state,
    invoiceAmountGross: isNaN(payload) ? 0 : payload,
  })),
  handleAction(setInvoiceLineItems, (state, { payload }) => ({
    ...state,
    lineItems: payload,
  })),
  handleAction(setInvoiceCurrency, (state, { payload }) => ({
    ...state,
    invoiceCurrency: payload,
  })),
  handleAction(setInvoiceDueDateTime, (state, { payload }) => ({
    ...state,
    invoiceDueDateTime: payload,
  })),
  handleAction(setInvoiceIssueDateTime, (state, { payload }) => ({
    ...state,
    invoiceIssueDateTime: payload,
  })),
  handleAction(setDefaultInvoiceState, () => defaultState),
  handleAction(setPayerId, (state, { payload }) => ({
    ...state,
    customerContact: {
      ...state.customerContact,
      id: payload,
    },
  })),
  handleAction(setCreatedReceivable, (state, { payload }) => ({
    ...state,
    externalReceivable: payload,
  })),
  handleAction(setAddressInformation, (state, { payload }) => ({
    ...state,
    customerContact: {
      ...state.customerContact,
      shippingAddress: payload,
    },
  })),
  handleAction(setInvoiceReference, (state, { payload }) => ({
    ...state,
    paymentUniqueReference: payload,
  })),
]);

export default invoiceReducer;
