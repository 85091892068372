import { useState } from "react";
import { useHistory } from "react-router-dom";
import { CountryCode } from "libphonenumber-js/types";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  CustomerDetailsForm,
  FooterActionsButtons,
  Page,
  ScreenHeader,
  ScreenHeaderSubtitle,
} from "@APP/components";
import { CustomerValidationSchema, getErrorMessageByErrorCode } from "@APP/utils";
import CONFIG from "@APP/config";
import { SCREEN_PATHS } from "@APP/navigation";
import { useAlert, useHandleErrorCodes } from "@APP/hooks";
import { getErpId, hideLoader, showLoader, useAppDispatch } from "@APP/redux";
import { createCustomer } from "@APP/services/api";
import { Customer } from "@APP/types";
import { getCountryCallingCode } from "libphonenumber-js";
import { Provider } from "@APP/constants";

const CreateCustomer = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const alert = useAlert();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();

  const erpId = useSelector(getErpId);

  const [countryCode, setCountryCode] = useState<CountryCode>(
    CONFIG.INPUTS.DEFAULT_PHONE_COUNTRY_CODE,
  );

  const handleOnSubmit = async (customer: Customer) => {
    if (!erpId) return;

    try {
      dispatch(showLoader());

      await createCustomer(erpId, {
        name: customer.name!,
        email: customer.email || undefined,
        mobile:
          customer.mobile?.replace(`+${getCountryCallingCode(countryCode)}`, "") !== ""
            ? customer.mobile
            : undefined,
        shippingAddress: Provider.isMaverick
          ? {
              state: customer.state || undefined,
              city: customer.city || undefined,
              postcode: customer.zipCode || undefined,
              addressLines:
                customer.address || customer.addressLine2
                  ? [customer.address, customer.addressLine2]
                  : [],
            }
          : undefined,
      });
      history.push(SCREEN_PATHS.CUSTOMERS);
    } catch (error) {
      const errorData = error.response?.data;

      if (errorData?.errorCode === 6007) {
        return alert.open("Failure", getErrorMessageByErrorCode(errorData?.errorCode), [
          { text: "Okay" },
        ]);
      }

      const isHandled = handleErrorCodes(errorData?.errorCode);

      if (isHandled) return;

      alert.open(
        "Failure",
        "We were unable to create your customer contact. Please try again later.",
        [
          { text: "Cancel" },
          { text: "Try again", onClick: async () => await handleOnSubmit(customer) },
        ],
      );
    } finally {
      dispatch(hideLoader());
    }
  };

  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    touched,
    values,
    isValid,
  } = useFormik({
    initialValues: {
      name: "",
      email: undefined,
      mobile: undefined,
      address: undefined,
      addressLine2: undefined,
      state: undefined,
      city: undefined,
      zipCode: undefined,
    },
    validationSchema: CustomerValidationSchema(countryCode, t),
    onSubmit: handleOnSubmit,
  });

  return (
    <Page title="Create Customer">
      <form onSubmit={handleSubmit}>
        <ScreenHeader title="Create Customer" />
        <ScreenHeaderSubtitle subtitle="Please enter your customer details." />
        <CustomerDetailsForm
          values={values}
          touched={touched}
          errors={errors}
          countryCode={countryCode}
          setCountryCode={setCountryCode}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
        <FooterActionsButtons
          backButtonText="Back to Customers"
          backButtonDataTestId="backCustomer"
          continueButtonDataTestId="submitCustomerr"
          handleBackButton={() => history.push(SCREEN_PATHS.CUSTOMERS)}
          disabledContinueButton={!isValid || !values.name}
          typeButtonContinue="submit"
          continueButtonText="Save"
        />
      </form>
    </Page>
  );
};

export default CreateCustomer;
