import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, Card, CardContent, CardHeader, Divider, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { addDays } from "date-fns";

import { Page } from "@APP/components";
import { ErpId } from "@APP/constants";
import { ForecastChart } from "@APP/views/cashflowForecast/ForecastChart";
import { InvoiceType, Payable, Receivable, RTP, SortBy, SortType } from "@APP/types";
import {
  getBankAccounts,
  getForecastData,
  getForecastId,
  getPermissions,
  getUser,
  hideLoader,
  setDefaultForecastState,
  setForecastLoading,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { API } from "@APP/services";
import { useAccessPermission, useCashflowForecasting, useHandleErrorCodes } from "@APP/hooks";
import { errorCodeString, getAvailableFeaturesBasedOnERP } from "@APP/utils";

import PaymentRequestsChart from "./PaymentRequestsChart";
import InvoiceTableInformation from "./InvoiceTableInformation";
import BankAccounts from "./BankAccounts";
import LinkBankAccounts from "./LinkBankAccounts";

const useStyles = makeStyles((theme) => ({
  InvoicePaymentRequestsCardContent: {
    padding: "16px 10px",
    [theme.breakpoints.down("md")]: {
      padding: "16px 5px",
    },
  },
  cardActionsContainer: {
    paddingTop: "16px",
    paddingBottom: "0px",
    justifyContent: "center",
  },
  cardActionButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  featureIcons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { generateCashflowForecast, getCashflowForecast } = useCashflowForecasting();
  const { fetchAllPermissions } = useAccessPermission();
  const bankAccounts = useSelector(getBankAccounts);

  const [rtps, setRtps] = useState<RTP[] | null>([]);
  const [payables, setPayables] = useState<Payable[] | null>([]);
  const [receivables, setReceivables] = useState<Receivable[] | null>([]);
  const [errorCodePayables, setErrorCodePayables] = useState("");
  const [errorCodeReceivables, setErrorCodeReceivables] = useState("");
  const [errorCodeRtps, setErrorCodeRtps] = useState("");
  const [errorCodeCashflowForecast, setErrorCodeCashflowForecast] = useState("");
  const [showLinkAccounts, setShowLinkAccounts] = useState<boolean>(false);

  const user = useSelector(getUser);
  const forecastId = useSelector(getForecastId);
  const forecastData = useSelector(getForecastData);
  const permissions = useSelector(getPermissions);

  const cashflowFeatureEnabled = getAvailableFeaturesBasedOnERP(user!.erp!).includes(
    "CASHFLOW_FORECAST",
  );
  const supplierInvoicesFeatureEnabled = getAvailableFeaturesBasedOnERP(user!.erp!).includes(
    "SUPPLIER_INVOICES",
  );

  useEffect(() => {
    (async () => {
      dispatch(showLoader());
      await fetchAllPermissions();
      supplierInvoicesFeatureEnabled && (await getAllInvoicesByType(InvoiceType.Payables));
      await getAllRtps();
      await getAllInvoicesByType(InvoiceType.Receivables);
      dispatch(hideLoader());
    })();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setDefaultForecastState());
    };
  }, []);

  useEffect(() => {
    if (cashflowFeatureEnabled) {
      generateCashFlowDetails();
    }
  }, []);

  const generateCashFlowDetails = async () => {
    try {
      await generateCashflowForecast(addDays(new Date(), 6));
    } catch (error) {
      const errorData = error?.response?.data;
      const errorCode = errorCodeString(errorData?.errorCode);
      setErrorCodeCashflowForecast(errorCode);
    }
  };

  const onTryAgainGenerateForecast = async () => {
    if (forecastId) {
      try {
        dispatch(setForecastLoading(true));
        await getCashflowForecast(forecastId);
      } catch (error) {
        const errorData = error?.response?.data;
        const errorCode = errorCodeString(errorData?.errorCode);
        setErrorCodeCashflowForecast(errorCode);
      }
    } else {
      await generateCashFlowDetails();
    }
  };

  const getAllRtps = async (page = 0, entries = 50, prevData: Array<RTP> = []): Promise<void> => {
    try {
      const response = await API.getPaymentRequests(
        { page, entries },
        {},
        {
          sort_by: SortBy.dueDate,
          sort_type: SortType.asc,
        },
      );
      const data: Array<RTP> = [...prevData, ...response.data];

      if (response.links.next) {
        return getAllRtps(page + 1, entries, data);
      }

      setRtps(data);
    } catch (error) {
      const errorData = error?.response?.data;
      const errorCode = errorCodeString(errorData?.errorCode);
      setErrorCodeRtps(errorCode);
      setRtps(null);
      handleErrorCodes(errorData?.errorCode);
    }
  };

  const getAllInvoicesByType = async (
    invoiceType: InvoiceType,
    page = 0,
    entries = 50,
    prevData: Array<Receivable | Payable> = [],
  ): Promise<void> => {
    try {
      const response = await API.getInvoices(
        user?.erp as ErpId,
        invoiceType,
        { page, entries },
        { sort_by: SortBy.dueDate, sort_type: SortType.asc },
        user?.erp === ErpId.INTERNAL && invoiceType === InvoiceType.Receivables
          ? ["Native"]
          : undefined,
        true,
      );
      const data: Array<Receivable | Payable> = [...prevData, ...response.data];

      if (response.links.next) {
        return getAllInvoicesByType(invoiceType, page + 1, entries, data);
      }

      invoiceType === InvoiceType.Payables
        ? setPayables(data as Payable[])
        : setReceivables(data as Receivable[]);
    } catch (error) {
      const errorData = error?.response?.data;
      const errorCode = errorCodeString(errorData?.errorCode);
      invoiceType === InvoiceType.Payables
        ? setErrorCodePayables(errorCode)
        : setErrorCodeReceivables(errorCode);
      invoiceType === InvoiceType.Payables ? setPayables(null) : setReceivables(null);
      handleErrorCodes(errorData?.errorCode);
    }
  };

  const getForecastErrorMessageForChart = () => {
    if (!forecastData || !forecastData.balances?.items?.length) {
      return "Cash Flow Forecast can’t be built as we were unable to get your data. Please try again.";
    }

    return undefined;
  };

  return (
    <Page title="Dashboard">
      {!showLinkAccounts && !bankAccounts?.length && (
        <LinkBankAccounts clickState={setShowLinkAccounts} />
      )}
      {permissions?.bank_account?.view && showLinkAccounts && (
        <Box mb={3}>
          <BankAccounts />
        </Box>
      )}
      {(permissions?.invoice?.view || permissions?.rtp?.view) && bankAccounts?.length && (
        <Card elevation={4}>
          <CardHeader title={"Open Payment Requests"} id="dashboardTitle" />
          <Divider />
          <CardContent className={classes.InvoicePaymentRequestsCardContent}>
            <Grid container spacing={3}>
              {permissions?.invoice?.view && (
                <Grid item lg={supplierInvoicesFeatureEnabled ? 6 : 12} xs={12}>
                  <InvoiceTableInformation
                    title="Your Unpaid Invoices"
                    titleTestId="invoices-table-head-title"
                    subTitle={t("Dashboard.InvoiceTableInformation.Subtitle")}
                    subTitleTestId="invoices-table-head-subtitle"
                    data={receivables}
                    errorCodeMessage={errorCodeReceivables}
                    headerTableTitles={[
                      { title: "Due date", testId: "due-date" },
                      { title: "Customer", testId: "customer" },
                      { title: "Unique Reference", testId: "reference" },
                      {
                        title: `${user?.erp === ErpId.INTERNAL ? "Amount" : "Remaining Amount"}`,
                        testId: "amount",
                      },
                    ]}
                    type={InvoiceType.Receivables}
                    emptyListMessage={
                      user?.erp === ErpId.INTERNAL
                        ? "You have no unpaid Invoices. Click here to create a new invoice."
                        : "You have no unpaid Invoices. Click here to create a new invoice which will also be saved in your accounting package."
                    }
                    onTryAgain={() => getAllInvoicesByType(InvoiceType.Receivables)}
                  />
                </Grid>
              )}
              {supplierInvoicesFeatureEnabled &&
                permissions?.invoice?.view &&
                bankAccounts?.length &&
                !bankAccounts?.length && (
                  <Grid item lg={6} xs={12}>
                    <InvoiceTableInformation
                      title="Your Supplier Invoices"
                      titleTestId="supplier-table-head-title"
                      subTitle="Invoices due for payment."
                      subTitleTestId="supplier-table-head-subtitle"
                      data={payables}
                      errorCodeMessage={errorCodePayables}
                      headerTableTitles={[
                        { title: "Due date", testId: "due-date" },
                        { title: "Supplier", testId: "supplier" },
                        {
                          title: `${user?.erp === ErpId.INTERNAL ? "Amount" : "Remaining Amount"}`,
                          testId: "amount",
                        },
                      ]}
                      type={InvoiceType.Payables}
                      onTryAgain={() => getAllInvoicesByType(InvoiceType.Payables)}
                      emptyListMessage="There are no unpaid Supplier Invoices."
                    />
                  </Grid>
                )}
              {permissions?.rtp?.view && bankAccounts?.length && (
                <Grid item lg={12} xs={12}>
                  <PaymentRequestsChart
                    errorCodeMessage={errorCodeRtps}
                    rtps={rtps}
                    onTryAgain={getAllRtps}
                  />
                </Grid>
              )}
              {cashflowFeatureEnabled && bankAccounts?.length && (
                <Grid item xs={12}>
                  <ForecastChart
                    errorMessage={getForecastErrorMessageForChart()}
                    errorCodeMessage={errorCodeCashflowForecast}
                    showNavigateToForecast
                    onTryAgain={onTryAgainGenerateForecast}
                  />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Page>
  );
};

export default Dashboard;
