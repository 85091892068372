import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { useFormik } from "formik";
import { Box, Button, Typography } from "@mui/material";

import CONFIG from "@APP/config";
import { AuthForm, AuthLayout, CommonTextField, Page, ReCaptcha } from "@APP/components";
import { SCREEN_PATHS } from "@APP/navigation";
import { ErrorCode, RegistrationActions } from "@APP/types";
import { emailValidationSchema, getErrorMessageByErrorCode } from "@APP/utils";
import { API, AppLocalStorage, LocalStorageKey } from "@APP/services";
import { useAlert } from "@APP/hooks";
import { AppState, getRegistrationEmail, setRegistrationEmail } from "@APP/redux";

interface Props {
  setRegistrationEmail: ReturnType<typeof mapDispatchToProps>["setRegistrationEmail"];
}

const REGISTRATION_EMAIL_VALIDATION_ERROR_CODES: ErrorCode[] = [7035, 1016];

const EmailRegistrationView = ({ setRegistrationEmail }: Props) => {
  const history = useHistory();
  const alert = useAlert();
  const { t } = useTranslation();

  const registrationEmail: string = useSelector(getRegistrationEmail);

  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      const refCode = AppLocalStorage.getItem(LocalStorageKey.authReference);
      const payload = {
        action: RegistrationActions.REGISTER_EMAIL,
        email: email,
        referralCode: refCode || undefined,
      };
      await API.register(payload);
      setRegistrationEmail(email);
      AppLocalStorage.removeItem(LocalStorageKey.authReference);
      history.push(SCREEN_PATHS.REGISTRATION_PHONE);
    } catch (error) {
      const errorCode = error?.response?.data?.errorCode;

      // Reset the Captcha data on Registration Failure
      onResetCaptcha();

      if (REGISTRATION_EMAIL_VALIDATION_ERROR_CODES.includes(errorCode)) {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          getErrorMessageByErrorCode(errorCode),
          [{ text: "Okay" }],
        );
      } else {
        alert.open(
          t("Errors.Common.Alerts.AlertTitles.Failure"),
          t("Errors.Registration.Alerts.SendEmail.Message"),
          [{ text: "Okay" }],
        );
      }
    }
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    touched,
    values,
  } = useFormik({
    initialValues: { email: registrationEmail || "" },
    validationSchema: emailValidationSchema(t),
    onSubmit,
  });

  const onCaptchaVerificationComplete = (token: string | null) => {
    setCaptchaToken(token);
  };

  const onResetCaptcha = () => {
    setCaptchaToken(null);
  };

  const renderMainContent = () => (
    <AuthForm title="Email" backwardPath={SCREEN_PATHS.REGISTRATION_TERMS_AND_CONDITIONS}>
      <Box mt={5}>
        <Typography variant="body2">
          {CONFIG.FEATURES.WORKING_CAPITAL_FINANCE_APPLICATION
            ? "Provide an email that we can use to contact you regarding your application.\nWe will send you a verification email to this email address to confirm your contact details."
            : "We will send you a verification email to your inbox, you will need to confirm your address to complete registration."}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit} noValidate>
        <CommonTextField
          label="Email Address"
          disabled={isSubmitting}
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={touched.email && errors.email}
          margin="normal"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          onValueChange={setFieldValue}
          type="email"
          id={"email-input"}
          value={values.email}
          autoComplete="email"
        />
        <Box display="flex" justifyContent="center" mt={2}>
          <ReCaptcha
            onCaptchaComplete={onCaptchaVerificationComplete}
            key={"registration-captcha"}
            captchaToken={captchaToken}
          />
        </Box>
        <Box mt={2}>
          <Button
            color="primary"
            disabled={isSubmitting || !captchaToken}
            fullWidth
            size="large"
            type="submit"
            id={"submit-button"}
            variant="contained">
            Send
          </Button>
        </Box>
      </form>
    </AuthForm>
  );

  return (
    <Page title="Get Started" display="flex" height="100%" p={0}>
      <AuthLayout mainContent={renderMainContent()} />
    </Page>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => ({
  setRegistrationEmail: (email: string) => dispatch(setRegistrationEmail(email)),
});

export default connect(null, mapDispatchToProps)(EmailRegistrationView);
