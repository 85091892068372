import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

import CONFIG from "@APP/config";
import { useAlert } from "@APP/hooks";

interface Props {
  onCaptchaComplete: (token: string | null) => void;
  captchaToken: string | null;
}

const ReCaptcha = ({ onCaptchaComplete, captchaToken }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation();

  const recaptcha = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    if (!captchaToken) {
      resetCaptcha();
    }
  }, [captchaToken]);

  const resetCaptcha = () => {
    recaptcha.current?.reset();
  };

  const onChange = (token: string | null) => {
    onCaptchaComplete(token);
  };

  const onError = () => {
    onCaptchaComplete(null);
    alert.open(t("Errors.Common.Alerts.AlertTitles.Error"), t("Errors.ErrorCodes.114"));
    resetCaptcha();
  };

  return (
    <ReCAPTCHA
      ref={recaptcha}
      id="react-google-recaptcha"
      sitekey={CONFIG.SERVICES.GOOGLE?.RECAPTCHA_SITE_KEY!}
      onChange={onChange}
      onExpired={onError}
      size="normal"
    />
  );
};

export default ReCaptcha;
