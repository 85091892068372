import CONFIG from "@APP/config";
import { CardPaymentConsent, CardPaymentsSettings } from "@APP/types";

import request from "../request";

export const getSquareConsentUrl = async (redirectUrl: string) => {
  const response = await request<{ redirectUrl: string; consentId: string }>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/cards/square/consent`,
  });

  return response.data;
};

export const authoriseSquareConsent = async ({
  consentId,
  code,
  redirectUrl,
}: {
  consentId: string;
  code: string;
  redirectUrl: string;
}) => {
  const response = await request<CardPaymentConsent>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/cards/square/consent/${consentId}/authorize`,
    params: {
      code,
    },
  });

  return response.data;
};

export const fetchCardPaymentsSettings = async () => {
  const response = await request<CardPaymentsSettings>({
    method: "GET",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/cards/settings`,
  });

  return response.data;
};

export const enableSquareCardPayments = async () => {
  const response = await request<CardPaymentsSettings>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/cards/square/enable`,
  });

  return response.data;
};

export const disableSquareCardPayments = async () => {
  const response = await request<CardPaymentsSettings>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/cards/square/disable`,
  });

  return response.data;
};

export const setDefaultSquareCardPayments = async () => {
  const response = await request<CardPaymentsSettings>({
    method: "POST",
    url: `${CONFIG.API.ROOT_URL}/${CONFIG.API.VERSION}/cards/square/default`,
  });

  return response.data;
};
