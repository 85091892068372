import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Card,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import {
  setActiveStep,
  setFormSubType,
  setFormType,
  setInvoiceFinanceFormData,
  setNextPath,
  setNextStepActive,
  setProductType,
} from "@APP/redux/actions/workingCapitalFinance";
import { CommonTextField, NumberFormatCustom, Page } from "@APP/components";
import WcfFooter from "@APP/components/layout/WCFLayout";
import { SCREEN_PATHS } from "@APP/navigation";
import WcfStepper from "@APP/components/layout/WCFLayout/WcfStepper";
import { useFormik } from "formik";
import { urgencyLevels } from "@APP/views/wcf/utils/dropdown";
import { getWorkingCapitalFinanceDetails } from "@APP/redux";
import { API } from "@APP/services";

import { removeUnderscoreAndCapitalize } from "../utils/utils";

const useStyles = makeStyles(() => ({
  container: {
    title: "Settings",
    display: "flex",
    flexDirection: "column",
  },
  cardContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    padding: "32px",
    alignItems: "center",
    minHeight: "80vh",
    borderRadius: "5px",
  },
}));

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function InvoiceLoanApplication() {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    wcfFormData: { invoiceFinanceForm },
  } = useSelector(getWorkingCapitalFinanceDetails);
  const { applicationId }: { applicationId: string } = useParams();
  const [hasPurpose, setHasPurpose] = useState(false);

  const validate = (values: {
    purpose: string | null;
    urgency: string;
    annualTurnover: number | null;
  }) => {
    let errors = {};
    if (!values.annualTurnover) {
      errors = { ...errors, annualTurnover: "Please enter annual turnover." };
    } else if (values.annualTurnover < 1) {
      errors = { ...errors, annualTurnover: "The annual turnover provided is invalid." };
    }
    return errors;
  };

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    isValid,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: {
      purpose: invoiceFinanceForm.purpose,
      urgency: invoiceFinanceForm.urgency,
      annualTurnover: invoiceFinanceForm.annualTurnover,
    },
    validate,
    onSubmit: () => {
      dispatch(
        setInvoiceFinanceFormData({
          ...values,
          purpose: values.purpose === "" ? null : values.purpose,
        }),
      );
      dispatch(setNextStepActive(isValid));
    },
  });

  useEffect(() => {
    (async () => {
      await retrieveApplication();
    })();
  }, []);

  const retrieveApplication = async () => {
    const res = await API.getApplicationDetailsById(applicationId);
    if (res && res?.data && res?.data?.praeturaStatus)
      history.push(`${SCREEN_PATHS.WCF_FINANCE_LISTING}`);
    const isInvoice = !res.data.assetFinanceRequest;
    dispatch(setProductType(res?.data?.assetFinanceRequest?.productType as string));
    dispatch(setFormType(isInvoice ? "Invoice Finance" : "Asset Finance"));
    if (!isInvoice)
      dispatch(
        setFormSubType(
          removeUnderscoreAndCapitalize(res.data.assetFinanceRequest?.productType ?? ""),
        ),
      );
  };

  useEffect(() => {
    dispatch(setNextStepActive(true));
    dispatch(setNextPath(`${SCREEN_PATHS.WCF_APPLICANT_DETAILS}/${applicationId}`));
    dispatch(setActiveStep(2));
  }, []);

  useEffect(() => {
    if (!values.purpose && !values.annualTurnover) {
      setHasPurpose(true);
      return;
    }
    if (!values.purpose) {
      setHasPurpose(false);
    } else {
      setHasPurpose(true);
    }
  }, [values.annualTurnover, values.purpose]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === " " && (event.target as HTMLInputElement).value.trim() === "") {
      event.preventDefault();
    }
  };

  return (
    <Page className={classes.container} p={0}>
      <Box display="flex" flexDirection="column" flexGrow={1} p={3}>
        <Card elevation={1} className={classes.cardContainer}>
          <form style={{ width: "100%" }}>
            <WcfStepper />
            <Grid container mt={1}>
              <Grid item xs={12} md={6}>
                <Typography
                  variant="h4"
                  component="p"
                  fontWeight={600}
                  color="primary"
                  paddingBottom={2}
                  id="invoiceLoanTitle">
                  Your invoice finance application
                </Typography>
                <Grid container spacing={1} pb={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Finance purpose:</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Please provide us a description of why you need finance.
                    </Typography>
                  </Grid>
                </Grid>
                <CommonTextField
                  label="Funding Purpose: "
                  id="wcfInvoiceFormFundingPurposeInput"
                  name="purpose"
                  value={values.purpose}
                  onChange={(e) => {
                    setFieldValue("purpose", e.target.value);
                    dispatch(
                      setInvoiceFinanceFormData({
                        ...values,
                        purpose: e.target.value === "" ? null : e.target.value,
                      }),
                    );
                  }}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  inputProps={{ maxLength: 10000 }}
                  multiline
                  rows={3}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ width: "100%" }}
                />
                <Typography variant="caption" color="textSecondary" id="invoiceLoanPurposeError">
                  {hasPurpose
                    ? "Maximum 10,000 characters."
                    : "You have not filled out the purpose of your application. Even though you can proceed without providing this information, we encourage you to provide a detailed description of why you need this funding in order to speed up the application process."}
                </Typography>
                <Typography variant="h6" m="28px 0px 16px">
                  When would you need the finance?
                </Typography>
                <Grid container marginTop={1}>
                  <Grid item xs={12}>
                    <TextField
                      name="urgency"
                      id="wcfInvoiceFormFundingUrgencyInput"
                      select
                      label="Urgency: "
                      value={values.urgency}
                      onChange={(e) => {
                        handleChange(e);
                        dispatch(
                          setInvoiceFinanceFormData({
                            ...values,
                            urgency: e.target.value,
                          }),
                        );
                      }}
                      onBlur={handleBlur}
                      error={Boolean(touched.urgency && errors.urgency)}
                      helperText={touched.urgency && errors.urgency}
                      sx={{ width: "100%" }}
                      defaultValue={urgencyLevels[0]}>
                      {urgencyLevels.map(({ label, value }) => (
                        <MenuItem
                          key={value}
                          value={value}
                          id={"wcfInvoiceFormFundingPurposeSelect_" + value}
                          style={getStyles(value, [], theme)}>
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid container marginTop={1}>
                  <Grid item xs={12}>
                    <Typography variant="h5" m="16px 0px 8px">
                      Eligibility
                    </Typography>
                    <Typography variant="body2" color="textSecondary" mb={2}>
                      To verify whether you are eligible for invoice financing, please tell us your
                      annual turnover
                    </Typography>
                    <NumberFormatCustom
                      name="annualTurnover"
                      id="wcfInvoiceFormAnnualTurnoverInput"
                      label="Annual turnover: *"
                      value={values.annualTurnover}
                      onChange={(e) => {
                        setFieldValue("annualTurnover", e.target.value);
                        dispatch(
                          setInvoiceFinanceFormData({
                            ...values,
                            annualTurnover: e.target.value ? Number(e.target.value) : null,
                          }),
                        );
                      }}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyPress}
                      error={Boolean(touched.annualTurnover && errors.annualTurnover)}
                      helperText={touched.annualTurnover && errors.annualTurnover}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      allowNegative={false}
                      sx={{ width: "100%" }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">£</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Card>
      </Box>
      <Box flexGrow={1} />
      <WcfFooter displayPrevButton={false} handleSubmit={handleSubmit} />
    </Page>
  );
}

export default InvoiceLoanApplication;
