import React, { useState } from "react";
import InputMask from "react-input-mask";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { CommonTextField } from "@APP/components";
import { getOnlyDigits } from "@APP/utils";
import CONFIG from "@APP/config";

import { CoPFormValues } from "./LinkCoPBankAccount";
import validationSchema from "./validationSchema";

type Props = {
  onFormSubmit: (formValues: CoPFormValues) => void;
};

export const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    paddingBottom: theme.spacing(3),
  },
  dialogTitle: {
    ...theme.typography.h4,
  },
}));

const LinkCoPBankAccountForm = ({ onFormSubmit }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOnSubmit = () => {
    dialogOpen && setDialogOpen(false);
    onFormSubmit({ ...values });
    clearConfirmFields();
  };

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    touched,
    values,
    isValid,
    setFieldValue,
    setFieldTouched,
  } = useFormik<CoPFormValues>({
    initialValues: {
      sortCode: "",
      accountHolderName: "",
      accountNumber: "",
      sortCodeConfirm: "",
      accountHolderNameConfirm: "",
      accountNumberConfirm: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleOnSubmit,
  });

  const clearConfirmFields = () => {
    // clear the touched and errors for the confirm fields to force the user to re-enter the details if they click the button again
    const confirmationFields = [
      "sortCodeConfirm",
      "accountHolderNameConfirm",
      "accountNumberConfirm",
    ];
    confirmationFields.forEach((field) => {
      setFieldTouched(field, false);
      setFieldValue(field, "");
    });
  };

  const onClickCancel = () => {
    clearConfirmFields();
    setDialogOpen(false);
  };

  return (
    <form onSubmit={handleOnSubmit}>
      <Box
        className={classes.fieldsContainer}
        display="grid"
        gridTemplateColumns={isSmSizeScreen ? "1fr" : "1fr 1fr 1fr"}
        columnGap={3}>
        <InputMask
          name="sortCode"
          value={getOnlyDigits(values.sortCode)}
          mask={CONFIG.INPUTS.BANK_SORT_CODE_MASK ?? ""}
          maskPlaceholder=""
          onChange={handleChange}
          onBlur={handleBlur}>
          <CommonTextField
            label="Sort Code"
            placeholder="Sort Code"
            error={Boolean(touched.sortCode && errors.sortCode)}
            helperText={touched.sortCode && errors.sortCode}
            margin="normal"
            name="sortCode"
            inputProps={{
              "data-testid": "sort-code-input",
            }}
            id="linkCoPBankAccountSortCode"
          />
        </InputMask>
        <CommonTextField
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          fullWidth
          name="accountNumber"
          label="Account Number"
          placeholder="Account Number"
          value={values.accountNumber}
          error={Boolean(touched.accountNumber && errors.accountNumber)}
          helperText={touched.accountNumber && errors.accountNumber}
          inputProps={{
            maxLength: 8,
            "data-testid": "account-number-input",
          }}
          id="linkCoPBankAccountAccountNumber"
        />
        <CommonTextField
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          fullWidth
          type="text"
          name="accountHolderName"
          label="Business Name"
          placeholder="Business Name"
          value={values.accountHolderName}
          error={Boolean(touched.accountHolderName && errors.accountHolderName)}
          helperText={
            (touched.accountHolderName && errors.accountHolderName) ||
            "This is your business name and not username."
          }
          inputProps={{
            "data-testid": "account-holder-name-input",
          }}
          id="linkCoPBankAccountHolderName"
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={() => setDialogOpen(true)}
          disabled={Boolean(
            errors.accountHolderName ||
              errors.accountNumber ||
              errors.sortCode ||
              !values.accountHolderName ||
              !values.accountNumber ||
              !values.sortCode,
          )}
          data-testid="open-validate-dialog-button"
          id="openValidationDialogButton">
          Validate bank account details
        </Button>
        <Dialog
          open={dialogOpen}
          maxWidth="md"
          fullWidth
          PaperProps={{ elevation: 0 }}
          slotProps={{
            root: {
              style: {
                // zIndex is set to 15001 to ensure that the modal is displayed above the DashboardLayout's MenuButton
                zIndex: 15001,
              },
            },
          }}>
          <DialogTitle className={classes.dialogTitle} data-testid="modal-window-title">
            Please re-enter your sort code, account number and account holder's name.
          </DialogTitle>
          <DialogContent>
            <Box
              className={classes.fieldsContainer}
              display="grid"
              gridTemplateColumns={isSmSizeScreen ? "1fr" : "1fr 1fr 1fr"}
              columnGap={2}>
              <InputMask
                name="sortCodeConfirm"
                value={getOnlyDigits(values.sortCodeConfirm)}
                mask={CONFIG.INPUTS.BANK_SORT_CODE_MASK ?? ""}
                maskPlaceholder=""
                onChange={handleChange}
                onBlur={handleBlur}>
                <CommonTextField
                  label="Sort Code"
                  error={Boolean(touched.sortCodeConfirm && errors.sortCodeConfirm)}
                  helperText={touched.sortCodeConfirm && errors.sortCodeConfirm}
                  margin="normal"
                  name="sortCodeConfirm"
                  inputProps={{
                    "data-testid": "sort-code-confirm-input",
                  }}
                  id="linkCoPBankAccountSortCodeConfirm"
                />
              </InputMask>
              <CommonTextField
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                fullWidth
                name="accountNumberConfirm"
                label="Account Number"
                value={values.accountNumberConfirm}
                error={Boolean(touched.accountNumberConfirm && errors.accountNumberConfirm)}
                helperText={touched.accountNumberConfirm && errors.accountNumberConfirm}
                inputProps={{
                  maxLength: 8,
                  "data-testid": "account-number-confirm-input",
                  id: "account-number-confirm-input",
                }}
                id="linkCoPBankAccountAccountNumberConfirm"
              />
              <CommonTextField
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                fullWidth
                name="accountHolderNameConfirm"
                label="Business Name"
                value={values.accountHolderNameConfirm}
                error={Boolean(touched.accountHolderNameConfirm && errors.accountHolderNameConfirm)}
                helperText={touched.accountHolderNameConfirm && errors.accountHolderNameConfirm}
                inputProps={{
                  "data-testid": "account-holder-name-confirm-input",
                  id: "account-holder-name-confirm-input",
                }}
                id="linkCoPBankAccountHolderNameConfirm"
              />
            </Box>
            <Box
              display="grid"
              gridTemplateColumns={isSmSizeScreen ? "1fr" : "1fr 1fr"}
              columnGap={3}
              rowGap={isSmSizeScreen ? 1 : 0}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                onClick={() => handleSubmit()}
                type="submit"
                disabled={!isValid && !isSubmitting}
                data-testid="validate-and-submit-button"
                id="linkCoPBankAccountHolderValidateSubmitButton">
                Validate
              </Button>
              <Button
                variant="outlined"
                fullWidth
                color="primary"
                id="linkCoPBankAccountHolderValidateCancelButton"
                onClick={onClickCancel}>
                Cancel
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </form>
  );
};

export default LinkCoPBankAccountForm;
