import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import * as Yup from "yup";
import { useFormik } from "formik";
import { GetAppRounded } from "@mui/icons-material";

import { CommonTextField, NoteBox } from "@APP/components";
import { ErpId, OrgType, Provider } from "@APP/constants";
import { useAlert, useHandleErrorCodes, useOrganisationDetails } from "@APP/hooks";
import {
  getPermissions,
  getUser,
  hideLoader,
  setUserOrganisationData,
  showLoader,
  useAppDispatch,
} from "@APP/redux";
import { updateOrganisationDetails, uploadOrganisationLogo } from "@APP/services/api";
import CONFIG from "@APP/config";
import { UserOrganisation } from "@APP/types";

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: "auto",
    height: 120,
    width: 120,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    fontSize: 24,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      height: 120,
      width: 120,
      fontSize: 14,
    },
  },
  avatarContainer: {
    borderWidth: 1,
    borderColor: theme.palette.action.disabled,
    borderRadius: theme.shape.borderRadius,
    borderStyle: "solid",
  },
  avatarLabel: {
    position: "absolute",
    left: 5,
    top: -9,
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(0.8),
    paddingRight: theme.spacing(0.8),
  },
  avatarDownloadIconContainer: {
    position: "absolute",
    right: 8,
    top: -12,
    backgroundColor: theme.palette.background.paper,
  },
}));

const organisationDetailsValidationSchema = (isLimitedCompany: boolean) => {
  const schemaForSoleTrader = {
    companyName: Yup.string()
      .required("Please enter your Company Name.")
      .min(2, "Please enter from 2 to 60 symbols.")
      .max(60, "Please enter from 2 to 60 symbols.")
      .matches(
        /^[A-Za-z\d-: ]+$/,
        "This field may contain only letters, numbers, hyphen, colon and spaces.",
      ),
    companyAddress: Yup.string()
      .notRequired()
      .min(2, "Please enter from 2 to 100 symbols.")
      .max(100, "Please enter from 2 to 100 symbols.")
      .matches(
        /^[A-Za-z\d!@#$%^&*)(+=._\-/\\, ]+$/,
        "This field may contain only letters, numbers and special characters.",
      ),
    vatNumber: Yup.string()
      .notRequired()
      .min(9, "VAT number must be 9-12 characters, and contain only letters or numbers.")
      .max(11, "VAT number must be 9-12 characters, and contain only letters or numbers.")
      .matches(
        /^[A-Za-z\d]+$/,
        "VAT number must be 9-12 characters, and contain only letters or numbers.",
      ),
    companyAddressLine2: Yup.string()
      .notRequired()
      .min(2, "Please enter from 2 to 100 symbols.")
      .max(100, "Please enter from 2 to 100 symbols.")
      .matches(
        /^[A-Za-z\d!@#$%^&*)(+=._\-/\\, ]+$/,
        "This field may contain only letters, numbers and special characters.",
      ),
    companyAddressPostCode: Yup.string().notRequired(),
    companyAddressCity: Yup.string()
      .notRequired()
      .min(2, "Please enter from 2 to 30 symbols.")
      .max(30, "Please enter from 2 to 30 symbols.")
      .matches(/^[A-Za-z- ]+$/, "This field may contain only letters, hyphen and spaces."),
    companyAddressCountry: Yup.string().notRequired(),
  };

  if (isLimitedCompany) {
    return Yup.object().shape({
      ...schemaForSoleTrader,
      companyNumber: Yup.string()
        .required("Please enter a valid company number.")
        .length(8, "Please enter a valid company number.")
        .matches(/^[A-Za-z\d]+$/, "Please enter a valid company number."),
    });
  }

  return Yup.object().shape(schemaForSoleTrader);
};

const getOrgValues = (org?: UserOrganisation | null) => ({
  companyName: org?.companyInfo.name ?? "",
  companyNumber: org?.taxInfo.utrNumber ?? "",
  vatNumber: org?.taxInfo.vatRegistrationNumber ?? "",
  companyAddress: org?.companyInfo.address?.lines[0] ?? "",
  companyAddressLine2: org?.companyInfo.address?.lines[1] ?? "",
  companyAddressPostCode: org?.companyInfo.address?.postcode ?? "",
  companyAddressCity: org?.companyInfo.address?.city ?? "",
  companyAddressCountry: org?.companyInfo.address?.country ?? "",
  companyAddressState: org?.companyInfo.address?.state ?? "",
});

const OrganisationDetails = () => {
  const classes = useStyles();
  const alert = useAlert();
  const organisationDetailsHook = useOrganisationDetails();
  const { t } = useTranslation();
  const user = useSelector(getUser);
  const theme = useTheme();
  const handleErrorCodes = useHandleErrorCodes();
  const isSmSizeScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const permissions = useSelector(getPermissions);

  const [isEditMode, setIsEditMode] = useState(false);
  const [logoFile, setLogoFile] = useState<Blob>();
  const [organisationLogoSrc, setOrganisationLogoSrc] = useState<string>(
    user?.org?.logo ? user.org.logo : "",
  );

  const logoFileInputRef = useRef<HTMLInputElement | null>(null);
  const isDisabledField = user?.erp !== ErpId.INTERNAL || !isEditMode;

  const isLimitedCompany =
    user?.org?.orgType === OrgType.LIMITED_COMPANY ||
    user?.org?.orgType === OrgType.LIMITED_LIABILITY_PARTNERSHIP;

  const {
    values,
    errors,
    touched,
    dirty,
    isValid,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    validateForm,
    setValues,
    setFieldTouched,
  } = useFormik({
    initialValues: getOrgValues(user?.org),
    validationSchema: organisationDetailsValidationSchema(isLimitedCompany),
    onSubmit: async ({
      companyName,
      companyNumber,
      companyAddress,
      vatNumber,
      companyAddressLine2,
      companyAddressPostCode,
      companyAddressCity,
      companyAddressCountry,
      companyAddressState,
    }) => {
      let orgInfo;
      dispatch(showLoader());

      try {
        if (!user?.org) return;

        orgInfo = {
          ...user.org,
          companyInfo: {
            ...user.org.companyInfo,
            name: companyName,
            address: {
              ...user?.org?.companyInfo.address,
              lines: [companyAddress, companyAddressLine2],
              postcode: companyAddressPostCode,
              city: companyAddressCity,
              country: companyAddressCountry,
              state: companyAddressState,
            },
          },
          taxInfo: {
            ...user?.org?.taxInfo,
            utrNumber: companyNumber,
            vatRegistrationNumber: vatNumber,
          },
          name: companyName,
        };

        await updateOrganisationDetails(user!.org!.id, orgInfo);
      } catch (e) {
        const errorCode = e.response?.data?.errorCode;

        const isHandled = handleErrorCodes(errorCode);

        dispatch(hideLoader());

        if (isHandled) {
          return;
        }

        return alert.open(
          t("Errors.Common.Alerts.AlertTitles.Error"),
          t("Errors.Settings.Organisation.Alerts.OrganisationUpdating.Message"),
          [{ text: "Okay" }],
        );
      }

      if (logoFile) {
        try {
          const formData = new FormData();
          formData.append("logo", logoFile);

          const logoLink = await uploadOrganisationLogo(formData);

          setLogoFile(undefined);
          setOrganisationLogoSrc(URL.createObjectURL(logoFile));
          orgInfo.logo = logoLink;
        } catch (e) {
          const errorCode = e.response?.data?.errorCode;

          const isHandled = handleErrorCodes(errorCode);

          dispatch(hideLoader());

          if (isHandled) {
            return;
          }

          return alert.open(
            t("Errors.Common.Alerts.AlertTitles.Error"),
            "Your logo could not be uploaded. The file type must be png, jpg or jpeg and must be no bigger than 5MB.",
          );
        }
      }

      dispatch(setUserOrganisationData(orgInfo));
      resetForm({ values });
      setIsEditMode(false);
      dispatch(hideLoader());
    },
  });

  useEffect(() => {
    setValues(getOrgValues(user?.org));
  }, [user?.org]);

  const confirmationLogoContent = useCallback((logoFile: Blob) => {
    return (
      <>
        <DialogContent>
          <Box my={2}>
            <Typography variant="h4" align="center">
              Are you sure you want to use this image?
            </Typography>
          </Box>
          <Avatar
            className={classes.avatar}
            src={URL.createObjectURL(logoFile)}
            alt="Company Logo"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              alert.close();
              logoFileInputRef.current?.click();
            }}
            variant="outlined"
            color="primary"
            id="organizationDetailsPickNewButton">
            Pick a new one
          </Button>
          <Button
            onClick={() => {
              setLogoFile(logoFile);
              alert.close();
            }}
            variant="contained"
            color="primary"
            id="organizationDetailsConfirmButton">
            Confirm
          </Button>
        </DialogActions>
      </>
    );
  }, []);

  const onFileChange = (event: any) => {
    alert.render(confirmationLogoContent(event.target?.files?.[0]));
  };

  const handleEditClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsEditMode(true);
    let initialErrors = await validateForm();
    let errorFields = Object.keys(initialErrors);
    errorFields.forEach((field) => setFieldTouched(field, true, false));
  };

  const handleClickCancelButton = () => {
    resetForm();
    setLogoFile(undefined);
    setIsEditMode(false);
  };

  const renderCardAction = () => {
    if (!permissions.organisation.update) {
      return null;
    }

    if (
      user?.erp === ErpId.INTERNAL &&
      CONFIG.FEATURES.GENERAL_FEATURES.includes("NATIVE_INVOICING")
    ) {
      return (
        <CardActions>
          {isEditMode ? (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="primary"
                  id="organisation-details-save-button"
                  disabled={(!isValid || !dirty) && !logoFile}>
                  Save
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="button"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  id="organisation-details-cancel-button"
                  onClick={handleClickCancelButton}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Button
              type="button"
              variant="contained"
              fullWidth
              color="primary"
              id="organisation-details-edit-button"
              onClick={handleEditClick}>
              Edit
            </Button>
          )}
        </CardActions>
      );
    }
    return (
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          id="get-organisation-details-button"
          data-testid="get-organisation-details-button"
          onClick={async (e: SyntheticEvent) => {
            e.preventDefault();
            dispatch(showLoader());
            await organisationDetailsHook.updateOrganisationDetails();
            dispatch(hideLoader());
          }}>
          {t("Settings.OrganisationDetails.ContinueButton")}
        </Button>
      </CardActions>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card elevation={4}>
        <CardHeader
          title={t("Settings.OrganisationDetails.CardTitle")}
          subheader={t("Settings.OrganisationDetails.CardSubheader")}
          data-testid="organisation-card-header"
          id="organisation-card-header"
        />
        <Divider />
        <CardContent>
          <Box mb={2}>
            {user?.erp !== ErpId.INTERNAL && permissions.organisation.update ? (
              <Box mb={2}>
                <NoteBox>
                  {isLimitedCompany
                    ? t("Settings.RegisteredCompanyNote")
                    : t("Settings.NonRegisteredCompanyNote")}
                </NoteBox>
              </Box>
            ) : null}
            <Grid
              alignItems="center"
              container
              direction={isSmSizeScreen ? "column" : "row"}
              spacing={3}>
              <Grid item xs={12} md={5} lg={4} xl={3}>
                <Box display="flex" justifyContent="center">
                  <Box
                    className={user?.erp === ErpId.INTERNAL ? classes.avatarContainer : undefined}
                    p={4}
                    position="relative">
                    {!isDisabledField && (
                      <>
                        <input
                          ref={logoFileInputRef}
                          onChange={onFileChange}
                          type="file"
                          style={{ display: "none" }}
                          id="organizationDetailsFileInput"
                        />
                        <Typography
                          className={classes.avatarLabel}
                          color="textSecondary"
                          variant="caption">
                          Company Logo
                        </Typography>
                        <Box className={classes.avatarDownloadIconContainer}>
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => logoFileInputRef.current?.click()}
                            id="organizationGetAppButton">
                            <GetAppRounded fontSize="small" />
                          </IconButton>
                        </Box>
                      </>
                    )}
                    <Avatar
                      className={classes.avatar}
                      src={logoFile ? URL.createObjectURL(logoFile) : organisationLogoSrc}
                      alt="Company Logo">
                      {user?.org?.companyInfo.name.toUpperCase() || ""}
                    </Avatar>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <Box display="flex" flexDirection="column" gap={3}>
                  <Typography variant="h6" data-testid="organisation-details-title">
                    {t("Settings.OrganisationDetails.OrgDetailsFieldTitle")}
                  </Typography>
                  <CommonTextField
                    inputProps={{
                      readOnly: isDisabledField,
                      disabled: isDisabledField,
                      id: "company-name-input",
                      "data-testid": "company-name-input",
                    }}
                    fullWidth
                    label="Company name"
                    placeholder="Company name"
                    name="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    error={Boolean(touched.companyName && errors.companyName)}
                    helperText={touched.companyName && errors.companyName}
                    id="organizationDetailsCompanyName"
                  />
                  {Provider.isMoneyhub && isLimitedCompany && (
                    <CommonTextField
                      inputProps={{
                        readOnly: isDisabledField,
                        disabled: isDisabledField,
                        id: "company-number-input",
                        "data-testid": "company-number-input",
                      }}
                      fullWidth
                      label="Company Number"
                      placeholder="Company Number"
                      name="companyNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyNumber}
                      error={Boolean(touched.companyNumber && errors.companyNumber)}
                      helperText={touched.companyNumber && errors.companyNumber}
                      id="organizationDetailsCompanyNumber"
                    />
                  )}
                  {user!.erp === ErpId.INTERNAL && (
                    <CommonTextField
                      inputProps={{
                        readOnly: isDisabledField,
                        disabled: isDisabledField,
                        id: "vat-number-input",
                        "data-testid": "vat-number-input",
                      }}
                      fullWidth
                      label="VAT number"
                      placeholder="VAT number"
                      name="vatNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.vatNumber}
                      error={Boolean(touched.vatNumber && errors.vatNumber)}
                      helperText={touched.vatNumber && errors.vatNumber}
                      id="organizationDetailsVatNumber"
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Box mb={2} mt={2}>
            <Typography variant="h6" data-testid="company-address-title">
              Company address
            </Typography>
            <Box
              display="grid"
              gridTemplateColumns={isSmSizeScreen ? "1fr" : "1fr 1fr"}
              columnGap={3}
              rowGap={2}
              marginTop={2}>
              <CommonTextField
                inputProps={{
                  readOnly: isDisabledField,
                  disabled: isDisabledField,
                  id: "company-address-input",
                  "data-testid": "company-address-input",
                }}
                fullWidth
                name="companyAddress"
                label="Address"
                placeholder="Address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyAddress}
                error={Boolean(touched.companyAddress && errors.companyAddress)}
                helperText={touched.companyAddress && errors.companyAddress}
                id="organizationDetailsCompanyAddress"
              />
              <CommonTextField
                inputProps={{
                  readOnly: isDisabledField,
                  disabled: isDisabledField,
                  id: "company-address-line2-input",
                  "data-testid": "company-address-line2-input",
                }}
                fullWidth
                label="Address Line 2"
                placeholder="Address Line 2"
                name="companyAddressLine2"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyAddressLine2}
                error={Boolean(touched.companyAddressLine2 && errors.companyAddressLine2)}
                helperText={touched.companyAddressLine2 && errors.companyAddressLine2}
                id="organizationDetailsCompanyAddressLine2"
              />
              <CommonTextField
                inputProps={{
                  readOnly: isDisabledField,
                  disabled: isDisabledField,
                  id: "company-address-city-input",
                  "data-testid": "company-address-city-input",
                }}
                fullWidth
                label="City"
                placeholder="City"
                name="companyAddressCity"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyAddressCity}
                error={Boolean(touched.companyAddressCity && errors.companyAddressCity)}
                helperText={touched.companyAddressCity && errors.companyAddressCity}
                id="organizationDetailsCompanyAddressCity"
              />
              {Provider.isMoneyhub ? (
                <CommonTextField
                  inputProps={{
                    readOnly: isDisabledField,
                    disabled: isDisabledField,
                    id: "company-address-country-input",
                    "data-testid": "company-address-country-input",
                  }}
                  fullWidth
                  label="Country"
                  placeholder="Country"
                  name="companyAddressCountry"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyAddressCountry}
                  error={Boolean(touched.companyAddressCountry && errors.companyAddressCountry)}
                  helperText={touched.companyAddressCountry && errors.companyAddressCountry}
                  id="organizationDetailsCompanyAddressCountry"
                />
              ) : (
                <CommonTextField
                  inputProps={{
                    readOnly: isDisabledField,
                    disabled: isDisabledField,
                    id: "company-address-state-input",
                    "data-testid": "company-address-state-input",
                  }}
                  fullWidth
                  label="State"
                  placeholder="State"
                  name="companyAddressState"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.companyAddressState}
                  error={Boolean(touched.companyAddressState && errors.companyAddressState)}
                  helperText={touched.companyAddressState && errors.companyAddressState}
                  id="organizationDetailsCompanyAddressState"
                />
              )}
              <CommonTextField
                inputProps={{
                  readOnly: isDisabledField,
                  disabled: isDisabledField,
                  id: "company-address-post-code-input",
                  "data-testid": "company-address-post-code-input",
                }}
                fullWidth
                label={t("Settings.OrganisationDetails.PostcodeFieldTitle")}
                placeholder={t("Settings.OrganisationDetails.PostcodeFieldTitle")}
                name="companyAddressPostCode"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyAddressPostCode}
                error={Boolean(touched.companyAddressPostCode && errors.companyAddressPostCode)}
                helperText={touched.companyAddressPostCode && errors.companyAddressPostCode}
                id="organizationDetailsCompanyAddressPostCode"
              />
            </Box>
          </Box>
        </CardContent>
        {renderCardAction()}
      </Card>
    </form>
  );
};

export default OrganisationDetails;
