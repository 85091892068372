import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  AppState,
  getErpId,
  setBankLedgers,
  setClearingLedger,
  useAppDispatch,
  store,
} from "@APP/redux";
import { useAccessPermission, useAlert, useHandleErrorCodes } from "@APP/hooks/index";
import { capitalize } from "@APP/utils";
import { ErpId, TabsName } from "@APP/constants";
import { SCREEN_PATHS } from "@APP/navigation";
import { API } from "@APP/services";
import { NO_CLEARING_LEDGER_CREATED_ERROR_CODE } from "@APP/services/api";
import { CoPStatus } from "@APP/types";
import CONFIG from "@APP/config";

const useCheckLedgersLinked = () => {
  const alert = useAlert();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleErrorCodes = useHandleErrorCodes();
  const { t } = useTranslation();
  const { fetchAllPermissions } = useAccessPermission();

  const erpId = useSelector(getErpId);

  const checkLedgersLinked = useCallback(
    async (checkClearingLedger?: boolean, state?: AppState) => {
      try {
        if (erpId === ErpId.INTERNAL) return true;

        /**
         * For Metro Bank, before checking the ledger, we need to check that CoP Bank Account
         * has been verified. If not, we need to show the CoP Bank Account status screen.
         * store.getState() - is used as updating CoP status happens right before the gateway call
         * and the state has no enought time to updated state to use relevant value here.
         */
        if (
          CONFIG.FEATURES.SETUP.CONFIRMATION_OF_PAYEE &&
          store.getState().auth.CoPStatus !== CoPStatus.Success
        ) {
          return true;
        }

        if (checkClearingLedger) {
          const isClearingLedgerLinked = await checkClearingLedgerLinked(state);
          if (!isClearingLedgerLinked) return false;
        }

        let bankLedgers;
        let permissions;

        if (state) {
          bankLedgers = state.auth.bankLedgers;
          permissions = state.access;
        } else {
          const { data: bankLedgersRes } = await API.fetchLinkedERPLedgers(erpId!);
          const permissionsRes = await fetchAllPermissions();
          bankLedgers = bankLedgersRes;
          permissions = permissionsRes;

          dispatch(setBankLedgers(bankLedgersRes));
        }

        if (!bankLedgers.length) {
          const bankLedgerPermissonAccessed =
            !!permissions?.bank_ledger?.create && !!permissions?.bank_ledger?.view;

          alert.open(
            t("Errors.InvoiceCreation.Alerts.APLedgersMissing.Title", {
              ACCOUNTING_PACKAGE: capitalize(erpId),
            }),
            t(
              bankLedgerPermissonAccessed
                ? "Errors.InvoiceCreation.Alerts.APLedgersMissing.Message"
                : "Errors.InvoiceCreation.Alerts.APLedgersMissing.NoPermissionMessage",
              {
                ACCOUNTING_PACKAGE: capitalize(erpId),
              },
            ),
            bankLedgerPermissonAccessed
              ? [
                  {
                    text: "Cancel",
                  },
                  {
                    text: "Select Bank Ledgers",
                    onClick: () =>
                      history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`),
                  },
                ]
              : [{ text: "Cancel" }],
            { persistent: true },
          );

          return false;
        }
        return true;
      } catch (e) {
        const errorData = e?.response?.data;

        const isHandled = handleErrorCodes(errorData?.errorCode);

        if (!isHandled)
          history.push({
            pathname: SCREEN_PATHS.APP_ERROR,
            state: {
              errorMessage: t("Errors.InvoiceCreation.Alerts.FetchBank.Message"),
              errorCode: errorData?.errorCode,
            },
          });

        return false;
      }
    },
    [handleErrorCodes, erpId],
  );

  const checkClearingLedgerLinked = useCallback(
    async (state?: AppState) => {
      try {
        if (erpId === ErpId.INTERNAL) return true;

        let clearingLedger;

        if (state) {
          clearingLedger = state.auth.clearingLedger;
        } else {
          const clearingLedgerRes = await API.fetchClearingLedger(erpId!);
          clearingLedger = clearingLedgerRes;

          dispatch(setClearingLedger(clearingLedger));
        }

        if (clearingLedger) {
          return true;
        }

        const genericError = {
          response: { data: { errorCode: NO_CLEARING_LEDGER_CREATED_ERROR_CODE } },
        };
        throw genericError;
      } catch (e) {
        const errorData = e?.response?.data;

        if (errorData.errorCode === NO_CLEARING_LEDGER_CREATED_ERROR_CODE) {
          alert.open(
            t(`Errors.ErrorCodes.${NO_CLEARING_LEDGER_CREATED_ERROR_CODE}.Title`, {
              erpId: capitalize(erpId),
            }),
            t(`Errors.ErrorCodes.${NO_CLEARING_LEDGER_CREATED_ERROR_CODE}.Message`, {
              erpId: capitalize(erpId),
            }),
            [
              {
                text: "Cancel",
              },
              {
                text: "Create clearing ledger",
                onClick: () =>
                  history.push(`${SCREEN_PATHS.SETTINGS}?tab=${TabsName.ACCOUNTING_PACKAGE}`),
              },
            ],
            { persistent: true },
          );
        } else {
          const isHandled = handleErrorCodes(errorData?.errorCode);

          if (!isHandled) {
            history.push({
              pathname: SCREEN_PATHS.APP_ERROR,
              state: {
                errorMessage: t("Errors.InvoiceCreation.Alerts.FetchBank.Message"),
                errorCode: errorData?.errorCode,
              },
            });
          }
        }

        return false;
      }
    },
    [erpId],
  );

  return { checkLedgersLinked, checkClearingLedgerLinked };
};

export default useCheckLedgersLinked;
